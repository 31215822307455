import { ElementRef, Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { CapacitorHttp } from '@capacitor/core';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { AppRoute } from 'src/app/constants/app.route';
import { environment } from 'src/environments/environment';
import { InHouseProvider } from '../provider-builder/interfaces/in-house-provider';
import { PlatformAbstractService } from './platform-abstract.service';

@Injectable({ providedIn: 'root' })
export class PlatformMobileService extends PlatformAbstractService {
  constructor(protected router: Router, protected zone: NgZone) {
    super(router, zone);
  }

  hookDeepLinks(): void {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        const authcallbackSegment = `${AppRoute.AuthCallBackMobile}`;
        const authcallback = authcallbackSegment + event.url.split(authcallbackSegment)[1];
        this.onAppUrlOpen(authcallback);
      });
    });
  }

  onAppUrlOpen(authcallback: string): void {
    this.router.navigateByUrl(`${authcallback}&app_redirect=true`);
  }

  // Submit forms using capacitor http client
  // Resolves cors errors and specific webview ios behavior (prompting user to download the page after submission)
  onSubmit(options: {
    form: ElementRef<any>;
    username: string;
    password: string;
    inHouseProvider: InHouseProvider;
  }): void {
    CapacitorHttp.post({
      url: `${environment.EduSpireApi.domainSSOUrl}/sso/login`,
      data: {
        username: options.username,
        password: options.password,
        client_id: options.inHouseProvider.client_id,
        redirect_uri: `/${AppRoute.AuthCallBackMobile}`,
        response_type: options.inHouseProvider.response_type,
        scope: options.inHouseProvider.scope,
        code_challenge: options.inHouseProvider.code_challenge,
        code_challenge_method: options.inHouseProvider.code_challenge_method,
        state: options.inHouseProvider.state
      },
      headers: { 'content-type': 'application/json' }
    }).then((res: any) => {
      if (res.url.split(AppRoute.Login)[1]) {
        this.router.navigateByUrl(AppRoute.AuthCallBackMobile, {skipLocationChange: true}).then(() => {
          this.router.navigateByUrl(`${AppRoute.Login}${res.url.split(AppRoute.Login)[1]}`);
        });
      } else {
        this.router.navigateByUrl(`${AppRoute.AuthCallBackMobile}${res.url.split(AppRoute.AuthCallBackMobile)[1]}`);
      }
    });
  }

}
