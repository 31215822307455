import { Injectable } from '@angular/core';
import { parseJsonTo, stringToDate } from '../../utils/utils';
import { AuthTokenRequest } from '../http/auth/interface/auth-token-request';
import { SchoolAuthExternal } from '../http/auth/interface/school-auth-external';
import { School } from '../http/school/interfaces/school';
import { AuthAbstractService } from './auth-abstract.service';

@Injectable({
  providedIn: 'root'
})
export abstract class AuthUserService extends AuthAbstractService {
  setSchoolUserAuthority(school: School): void {
    this.localStorageService.setItem('schoolUserAuthority', JSON.stringify(school));
  }

  /**
   * Returns current selected school
   */
  getSchoolUserAuthority(): School | null {
    const schoolUserAuthorityString = this.localStorageService.getItem('schoolUserAuthority');

    if (!schoolUserAuthorityString) {
      return null;
    }

    const schoolUserAuthority = parseJsonTo<School>(schoolUserAuthorityString);

    if (!schoolUserAuthority) {
      return null;
    }

    return schoolUserAuthority;
  }

  async setUserData(authTokenRequest: AuthTokenRequest): Promise<void> {
    const authToken = await this.authService.post(authTokenRequest).toPromise();
    this.tokenStorageService.set(authToken.accessToken);
    this.localStorageService.setItem('tokenExpiration', authToken.expires);
  }

  setUserDataBySchoolAuthExternal(schoolAuthExternal: SchoolAuthExternal): void {
    this.localStorageService.clearAll();

    this.tokenStorageService.set(schoolAuthExternal.token);
    this.localStorageService.setItem('tokenExpiration', schoolAuthExternal.expires);
    this.localStorageService.setItem('schoolUserAuthority', JSON.stringify(schoolAuthExternal.school));
  }

  getToken(): string {
    return this.tokenStorageService.get();
  }

  getTokenExpirationDate(): Date | undefined {
    const tokenExpiration = this.localStorageService.getItem('tokenExpiration');

    if (tokenExpiration) {
      return stringToDate(tokenExpiration);
    }

    return;
  }

  async refreshToken(): Promise<boolean> {
    try {
      const accessToken = this.getToken();
      const authToken = await this.authService.postRefreshToken(accessToken).toPromise();
      this.tokenStorageService.set(authToken.accessToken);
      this.localStorageService.setItem('tokenExpiration', authToken.expires);
  
      return !this.isTokenExpired();
    } catch (error) {
      console.error('Error refreshing token:', error);
      return false;
    }
  }

  logOut(): void {
    const accessToken = this.getToken();
    this.authService.postRevokeToken(accessToken).subscribe(() => {
      this.clear();
      this.redirectToLogin();
    });
  }

  clear(): void {
    this.localStorageService.clearAll();
  }
}
