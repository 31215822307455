export enum StudentAttendanceType {
  Absent,
  Present,
  LateExcused,
  LateUnexcused,
  HastToAttendActivity,
  HastToAttendLunch,
  WasManuallyExcluded,
  DueToCapFileExclusion,
  ABPolarityExclusion,
  AdminCancellation
}
