import { HttpClient, HttpHeaders } from '@angular/common/http';

import { AuthToken } from './interface/auth-token';
import { AuthTokenImpersonateRequest } from './interface/auth-token-impersonate-request';
import { AuthTokenRequest } from './interface/auth-token-request';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthService {
  public static readonly SIMULATE_ENDPOINT: string = 'authentication/client-token/simulate';
  constructor(private http: HttpClient) {}

  /**
   * Creates a JWT token
   */
  post(authTokenRequest: AuthTokenRequest): Observable<AuthToken> {
    return this.http.post<AuthToken>(
      `${environment.EduSpireApi.domainApiUrl}/authentication/client-token`,
      authTokenRequest
    );
  }

  /**
   * Refresh a JWT token
   */
  postRefreshToken(accessToken: string): Observable<AuthToken> {
    const requestOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${accessToken}`
      })
    };

    return this.http.post<AuthToken>(
      `${environment.EduSpireApi.domainApiUrl}/authentication/client-token/refresh`,
      null,
      requestOptions
    );
  }

  /**
   * Revoke a JWT token
   */
  postRevokeToken(accessToken: string): Observable<string> {
    const requestOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${accessToken}`
      })
    };

    return this.http.post<string>(
      `${environment.EduSpireApi.domainApiUrl}/ftm/authentication/client-token/logout`,
      null,
      requestOptions
    );
  }

  /**
   * Creates impersonate JWT token
   */
  postImpersonateTokenByUserUuid(authTokenRequest: AuthTokenImpersonateRequest): Observable<AuthToken> {
    return this.http.post<AuthToken>(
      `${environment.EduSpireApi.baseUrl}/${AuthService.SIMULATE_ENDPOINT}`,
      authTokenRequest
    );
  }
}
