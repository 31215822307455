import { Injectable } from '@angular/core';
import { DesktopPushSubscriberService } from './desktop-push-subscriber.service';
import { environment } from '../../../../../environments/environment';
import { MobilePushSubscriberService } from './mobile-push-subscriber.service';

@Injectable({
    providedIn: 'root'
})
export class PushSubscriberService {
    public constructor(
        private desktopPushSubscriber: DesktopPushSubscriberService,
        private mobilePushSubscriber: MobilePushSubscriberService
    ) {
    }

    public subscribe()
    {
        if (environment.mobile) {
            this.mobilePushSubscriber.subscribe();
        } else {
            this.desktopPushSubscriber.subscribe();
        }
    }
}
