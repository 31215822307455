import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppConfig } from 'src/app/configs/app.config';
import { EventType } from 'src/app/constants/events-type';
import { DestroyNotifierComponent } from 'src/app/core/component/destroy/destroy-notifier.component';
import { AuthImpersonateUserService } from 'src/app/core/services/auth/auth-impersonate-user.service';
import { AuthUserService } from 'src/app/core/services/auth/auth-user.service';
import { EventManagerService } from 'src/app/core/services/event-manager/event-manager.service';
import { EventContent } from 'src/app/core/services/event-manager/models/event-content';
import { Paging } from 'src/app/core/services/http/general/classes/paging';
import { Status } from 'src/app/core/services/http/general/enums/status';
import { UserAdapterService } from 'src/app/core/services/http/user/adapters/user-adapter.service';
import { User } from 'src/app/core/services/http/user/interfaces/user';
import { UserService } from 'src/app/core/services/http/user/user.service';
import { ObservableWrapperService } from 'src/app/core/services/observable-wrapper/observable-wrapper.service';
import { AutoCompleteItem } from '../../shared/auto-complete/interfaces/autocomplete-item';
import { SimulateService } from '../services/simulate.service';
import { getFormGroup } from './form-group/form.group';

/**
 * Manages simulate functionality
 * The simulate UI flow is as follow:
 * 1.User select to impersonate
 * - from the user list
 * - or from the main menu
 * 2.App redirect user to his default route defined by his role
 * 3.User that have been selected becomes default selected for the autocomplete list
 * 4.Return button will clean impersonated data and will redirect user to his default route
 * 5.Simulate bar will become hidden
 */
@Component({
  selector: 'app-simulate-bar',
  templateUrl: './simulate-bar.component.html',
  styleUrls: ['./simulate-bar.component.scss']
})
export class SimulateBarComponent extends DestroyNotifierComponent implements OnInit, OnDestroy {
  // Triggers event with class (simulate) if we choose to show up the simulate bar
  // Uses from the parent that wraps whole app
  @Output() simulateClassChange = new EventEmitter<'simulate'>();

  paging = new Paging({ pageSize: AppConfig.AutoComplete.pageSize });
  shouldShowSimulateBar = false;
  form = getFormGroup();

  users: AutoCompleteItem<User>[] = [];
  private subscription: Subscription | undefined;

  constructor(
    private authUserService: AuthUserService,
    private simulateService: SimulateService,
    private eventManager: EventManagerService,
    private userService: UserService,
    private userAdapterService: UserAdapterService,
    private authImpersonateUserService: AuthImpersonateUserService,
    private observableWrapperService: ObservableWrapperService
  ) {
    super();
  }

  ngOnInit(): void {
    this.updateShouldShowSimulateBar();
    this.onSimulateVisibilityChange();

    const user = this.authImpersonateUserService.getUser();

    if (user) {
      this.setUserFormControlValue(user);
      this.simulateClassChange.emit('simulate');
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.eventManager.destroy(this.subscription);
    }
  }

  async onSelectChangeUser(item: AutoCompleteItem<User | object>): Promise<void> {
    if (!item || !item.extraValues?.firstValue.hasOwnProperty('role')) {
      return;
    }

    this.form?.controls.user.setValue(item.value);
    const user = item.extraValues?.firstValue as User;

    if (user) {
      await this.simulateService.impersonate(user);
    }
  }

  onSearchChangeUser(filter: string): void {
    // Forces using of user's token not impersonate one,
    // to be able to get users list even if you are logged in as student
    this.observableWrapperService.unsubscribeWrapper<User[]>(
      this.userService.getAll(
        this.paging,
        { filter, status: Status.Active },
        { Authorization: `Bearer ${this.authUserService.getToken()}` }
      ),
      this.destroyNotify,
      users => {
        this.users = this.userAdapterService.userToAutoCompleteItem(users);
      }
    );
  }

  /**
   * Cleans Impersonate data from the localStorage/sesstionStorage
   * Returns main user to his default route
   */
  onReturn(): void {
    this.simulateService.return();
  }

  /**
   * Handles SimulateVisibility event
   */
  private onSimulateVisibilityChange(): void {
    this.subscription = this.eventManager.subscribe(
      EventType.SimulateShow,
      async (eventContent: EventContent<User>) => {
        this.shouldShowSimulateBar = true;

        if (this.shouldShowSimulateBar) {
          this.simulateClassChange.emit('simulate');
        }

        if (eventContent?.content) {
          await this.onSimulateVisibilityChangeHandler(eventContent.content);
        }
      }
    );
  }

  private async onSimulateVisibilityChangeHandler(user: User): Promise<void> {
    if (!this.shouldShowSimulateBar) {
      this.hideSimulateBar();
    } else {
      await this.showSimulateBar(user);
    }
  }

  private hideSimulateBar(): void {
    this.authImpersonateUserService.clear();
    this.simulateClassChange.emit();
  }

  private async showSimulateBar(user: User): Promise<void> {
    // Executes if we choose impersonate button from the user list
    this.setUserFormControlValue(user);
    await this.simulateService.impersonate(user);
  }

  /**
   * Sets selected user as a default in the autocomplete list
   * Handles trigered event (SimulateVisibility)
   */
  private setUserFormControlValue(user: User): void {
    const item = this.userAdapterService.userToAutoCompleteItem([user]);

    if (item.length === 1) {
      this.form.controls.user.setValue(item[0]);
    }
  }

  /**
   * Updates visibility of the simulate bar
   */
  private updateShouldShowSimulateBar(): void {
    const schoolUserAuthority = this.authImpersonateUserService.getSchoolUserAuthority();

    if (schoolUserAuthority) {
      this.shouldShowSimulateBar = true;
    }
  }
}
