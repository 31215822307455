import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthFactoryService } from '../../auth/auth-factory.service';
import { RestGenericUtilService } from '../rest-generic-util.service';
import { RestGeneric } from '../rest.generic';
import { User } from '../user/interfaces/user';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthenticatedUserService extends RestGeneric<any, User, any> {
  public isGainsightPxDataSend = false;
  public currentAuthenticatedUser: User | undefined = undefined;
  public authenticatedUserChanged: EventEmitter<User> = new EventEmitter<User>();
  constructor(
    protected http: HttpClient,
    protected authFactoryService: AuthFactoryService,
    protected restGenericUtilService: RestGenericUtilService
  ) {
    super(http, authFactoryService, 'authenticated-user', restGenericUtilService);
  }

  getAuthenticatedUser(): Observable<User> {
    return this.http.get<User>(`${this.baseApiUrl}/${this.schoolUUid}/membership/authenticated-user`)
        .pipe(
            tap(
                (authenticatedUser: User) => {
                  if (this.currentAuthenticatedUser === undefined || this.currentAuthenticatedUser.uuid !== authenticatedUser.uuid) {
                    this.authenticatedUserChanged.emit(authenticatedUser);
                  }

                  this.currentAuthenticatedUser = authenticatedUser;
                },
                () => this.currentAuthenticatedUser = undefined
            )
        );
  }

    patch(updateType: any): Observable<User> {
        return this.http.put<User>(`${this.baseApiUrl}/${this.schoolUUid}/membership/authenticated-user`, updateType);
    }
}
