<div class="blank-header">
    <img src="assets/images/flex-logo-color.svg" width="126" alt="Eduspire Solutions Logo" />
  </div>
  
  <div class="blank-page">
    <div class="p-4 text-center">
      <h1 class="mb-0">{{ 'NOT_FOUND' | translate }}</h1>
      <p>{{ 'NOT_FOUND_DESCRIPTION' | translate }}</p>
      <button type="button" class="btn btn-primary btn-md-padded btn-radius" routerLink="/">
          {{ 'BACK_TO_APP' | translate }}
      </button>
    </div>
  </div>