import { Injectable } from '@angular/core';
import { getMessaging, getToken } from 'firebase/messaging';
import { NotificationService } from '../../http/notification/notification.service';
import { environment } from '../../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class DesktopPushSubscriberService {
    public constructor(
        private notificationService: NotificationService
    ) {}

    public subscribe()
    {
        const messaging = getMessaging();
        getToken(messaging, {vapidKey: environment.Firebase.vapidKey}).then(
            (currentToken) => {
                if (currentToken) {
                    this.notificationService.subscribeForDeviceNotifications({
                        deviceToken: currentToken
                    }).subscribe();
                }
            }
        );
    }
}
