import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { Observable } from 'rxjs';
import { PlatformMobileService } from '../platform-mobile.service';

@Injectable({ providedIn: 'root' })
export class IosPlatformService extends PlatformMobileService {
  constructor(
    protected router: Router,
    protected zone: NgZone,
    private platform: Platform,
    private screenOrientation: ScreenOrientation
  ) {
    super(router, zone);
    this.initPlatformClassChange();
  }

  private initPlatformClassChange(): void {
    this.platformClassChange = new Observable(observer => {
      this.platform.ready().then(() => {
        const platforms = {
          isIpad: this.platform.is('ipad'),
          isIphone: this.platform.is('iphone')
        };

        const platformClass = this.getPlatformClass(this.platform.isPortrait(), platforms);
        observer.next(platformClass);

        this.screenOrientation.onChange().subscribe(() => {
          const platformClassChanged = this.getPlatformClass(
            this.platform.isPortrait(),
            platforms,
            this.screenOrientation.type
          );

          observer.next(platformClassChanged);
        });
      });
    });
  }

  private getPlatformClass(
    isPortrait: boolean,
    platforms: { isIpad: boolean; isIphone: boolean },
    orientation?: string
  ): string {
    if (platforms.isIphone) {
      return this.getIphoneClass(isPortrait, orientation);
    } else if (platforms.isIpad) {
      return this.getIpadClass();
    } else {
      return '';
    }
  }

  private getIphoneClass(isPortrait: boolean, orientation?: string): string {
    if (isPortrait && !orientation) {
      return 'app-iphone';
    }

    if (orientation?.startsWith('portrait')) {
      return 'app-iphone';
    }

    return '';
  }

  private getIpadClass(): string {
    return 'app-ipad';
  }
}
