<ng-select
  [clearable]="true"
  [items]="items"
  [(ngModel)]="selectedItem"
  [compareWith]="compareWith"
  [notFoundText]="'NO_ITEMS_FOUND' | translate"
  [bindLabel]="'label'"
  [bindValue]="'value'"
  [loading]="isLoading"
  [placeholder]="'SEARCH' | translate"
  [appendTo]="'body'"
  (change)="onChange($event)"
  (open)="onOpen()"
  (search)="onSearch($event)"
  [disabled]="disabled"
  [multiple]="multiple"
>
  <ng-template ng-label-tmp let-item="item">
    <!-- Multiple off -->
    <ng-container *ngIf="!multiple; else multipleTemplate">
      <span *ngIf="item.extraLabels.firstLabel">{{ item.extraLabels.firstLabel }}</span>
      <span *ngIf="item.extraLabels.secondLabel">{{ item.extraLabels.secondLabel }}</span>
    </ng-container>

    <!-- Multiple on -->
    <ng-template #multipleTemplate>
      <span class="ng-value-icon left" (click)="onRemoveItem(item)" aria-hidden="true">×</span>
      <span *ngIf="item.extraLabels.firstLabel">{{ item.extraLabels.firstLabel }}</span>
    </ng-template>
  </ng-template>
  <ng-template ng-option-tmp let-item="item" let-index="index">
    <div class="option-title option-line-height">
      <span *ngIf="item.extraLabels.firstLabel">{{ item.extraLabels.firstLabel }}</span>
      <span *ngIf="item.extraLabels.secondLabel">{{ item.extraLabels.secondLabel }}</span>
    </div>
    <small class="option-meta option-line-height" *ngIf="item.extraLabels.thirdLabel || item.extraLabels.fourthLabel">
      {{ item.extraLabels.thirdLabel ?? item.extraLabels.fourthLabel }}
    </small>
  </ng-template>
</ng-select>
