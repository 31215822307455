import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AutoCompleteItem } from 'src/app/shared/auto-complete/interfaces/autocomplete-item';
import { RoomAdapterService } from '../../room/adapters/room-adapter.service';
import { SetPassword } from '../interfaces/set-password';
import { SetEmail } from '../interfaces/set-email';
import { Student } from '../interfaces/student';
import { Teacher } from '../interfaces/teacher';
import { User } from '../interfaces/user';
import { UserRequest } from '../interfaces/user-request';
import { UserUpdateRequest } from '../interfaces/user-update-request';

@Injectable({
  providedIn: 'root'
})
export class UserAdapterService {
  constructor(private roomAdapterService: RoomAdapterService) {}

  userToAutoCompleteItem(users: User[] | Student[] | Teacher[]): AutoCompleteItem<User>[] {
    if (!users) {
      return [];
    }

    return users.map((item: User | Student | Teacher) => {
      return {
        value: item.uuid,
        label: `${item.user.firstName} ${item.user.lastName} ${item.user.email} ${item.sisId}`,
        extraLabels: {
          firstLabel: item.user.firstName,
          secondLabel: item.user.lastName,
          thirdLabel: item.user.email,
          fourthLabel: item.sisId,
          value: item.user.email
        },
        extraValues: {
          firstValue: item
        }
      } as AutoCompleteItem<User | Student | Teacher>;
    });
  }

  formGroupToUserRequest(form: UntypedFormGroup): UserRequest | undefined {
    if (!form) {
      return;
    }

    return {
      status: form.controls.status.value,
      user: {
        firstName: form.controls.firstname.value,
        lastName: form.controls.lastname.value,
        title: form.controls.title.value || null,
        email: form.controls.email.value,
        password: form.controls.password.value
      },
      role: form.controls.role.value,
      graduationYear: form.controls.graduationYear?.value,
      sendConfirmationEmail: form.controls.sendConfirmationEmail?.value,
      preventFromArchiving: form.controls.preventFromArchiving?.value || false,
      sisId: form.controls.sisId?.value,
      room: (form.controls.defaultRoom?.value as AutoCompleteItem)?.value,
      isPrincipal: form.controls.isPrincipal?.value
    };
  }

  formGroupToUserSelfUpdateRequest(form: UntypedFormGroup): any | undefined {
    if (!form) {
      return;
    }

    return {
      user: {
        firstName: form.controls.firstname.value,
        lastName: form.controls.lastname.value,
        title: form.controls.title.value || null,
      },
      room: (form.controls.defaultRoom?.value as AutoCompleteItem)?.value
    };
  }
  formGroupToUserUpdateRequest(form: UntypedFormGroup, userUuid: string): UserUpdateRequest | undefined {
    if (!form) {
      return;
    }

    const userRequest = this.formGroupToUserRequest(form);

    if (!userRequest) {
      return;
    }

    const email = userRequest.user.email;
    const password = userRequest.user.password;

    return {
      ...userRequest,
      user: {
        uuid: userUuid,
        firstName: userRequest.user.firstName,
        lastName: userRequest.user.lastName,
        title: userRequest.user.title,
        password: password === '' ? null : password,
        email: email === '' ? null : email,
      }
    };
  }

  formGroupToSetPassword(form: UntypedFormGroup, rCode: string): SetPassword | undefined {
    if (!form) {
      return;
    }

    return {
      rCode,
      password: form.controls.password.value
    };
  }

  formGroupToResetPasswordInitiate(form: UntypedFormGroup): SetEmail {
    const email = form.controls.email.value;
    return {
      email
    };
  }

  authenticatedUserToFromGroupData(user: User, form: UntypedFormGroup): void {
    if (!form) {
      return;
    }

    form.patchValue({
      firstname: user.user.firstName,
      lastname: user.user.lastName,
      sisId: user.sisId,
      title: user.user.title?.name,
    });

    if (user.room) {
      const defaultRoom = this.roomAdapterService.roomToAutoCompleteItem([user.room]);

      if (defaultRoom.length === 1) {
        form.controls.defaultRoom.setValue(defaultRoom[0], { emitEvent: false });
      }
    }
  }

  userToFormGroupData(user: User, form: UntypedFormGroup): void {
    if (!form) {
      return;
    }

    form.patchValue({
      uuid: user.uuid,
      firstname: user.user.firstName,
      lastname: user.user.lastName,
      email: user.user.email,
      role: user.role.code,
      graduationYear: user.graduationYear,
      preventFromArchiving: user.preventFromArchiving,
      sisId: user.sisId,
      title: user.user.title?.name,
      status: user.status
    });

    if (user.room) {
      const defaultRoom = this.roomAdapterService.roomToAutoCompleteItem([user.room]);

      if (defaultRoom.length === 1) {
        form.controls.defaultRoom.setValue(defaultRoom[0], { emitEvent: false });
      }
    }
    form.controls.status.setValue(user.status, { emitEvent: false });

    if (user.designationCollection !== undefined && user.designationCollection.find(item => item.code == "principal")) {
      user.isPrincipal = true;
      form.controls.isPrincipal?.setValue(true, { emitEvent: false });
    }
  }

  userToUserUpdateRequest(user: User): UserUpdateRequest {
    return {
      status: user.status,
      user: {
        firstName: user.user.firstName,
        lastName: user.user.lastName,
        title: user.user.title?.name,
        email: user.user.email,
        uuid: user.user.uuid
      },
      role: user.role.code,
      graduationYear: user.graduationYear,
      preventFromArchiving: user.preventFromArchiving,
      sisId: user.sisId,
      room: user.room?.uuid,
      isPrincipal: user.isPrincipal
    };
  }
}
