import { Component, isDevMode, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CapacitorHttp } from '@capacitor/core';
import { AppRoute } from 'src/app/constants/app.route';
import { AuthUserService } from 'src/app/core/services/auth/auth-user.service';
import { AuthProvider } from 'src/app/core/services/http/general/enums/auth-provider';
import { environment } from 'src/environments/environment';
import { AuthTokenRequest } from '../../services/http/auth/interface/auth-token-request';
import { LocalStorageService } from '../../services/local-storage/local-storage.service';
import { AuthCallBackComponent } from '../auth-call-back/auth-call-back.component';

/**
 * Handles TParties and Eduspire oauth flow for IOS devices only
 */
@Component({
  selector: 'app-auth-call-back-mobile',
  templateUrl: './auth-call-back-mobile.component.html'
})
export class AuthCallBackMobileComponent extends AuthCallBackComponent implements OnInit {

  environment = environment;
  showUseWebBrowserButton = false;

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected authUserService: AuthUserService,
    protected router: Router,
    private localStorageService: LocalStorageService
  ) {
    super(activatedRoute, authUserService, router);
  }

  async ngOnInit(): Promise<void> {
    const authTokenRequest = await this.getAuthTokenRequest();
    // Uses @capacitor-community/http native http client which resolves CORS errors
    if (authTokenRequest) {
      CapacitorHttp.post({
        url: `${environment.EduSpireApi.domainApiUrl}/authentication/client-token`,
        method: 'POST',
        data: authTokenRequest,
        headers: { 'content-type': 'application/json' }
      }).then(res => {
        if (res.data?.accessToken && res.data?.expires) {
          this.localStorageService.setItem('token', res.data.accessToken);
          this.localStorageService.setItem('tokenExpiration', res.data.expires);
          this.authUserService.removeVerifierCode();
        }
        
        this.authUserService.redirectToLogin();
      });
    }
  }

  public goTo(to: string): void {
    switch (to) {
      case 'mobile':
        window.open('eduspire-app:/' + this.router.url, '_self');
        break;
      case 'web':
        window.open(`${environment.domainUrl}/${AppRoute.Login}`, '_self');
        break;
      case 'app':
        document.location.href = 'eduspire-app:/' + this.router.url;
        break;
    }
  }

  /**
   * Obtains all needed query params for creation of the token.
   * If the user comes from the web version which means he has redirected of some of the
   * TParty providers, we force the browser to open the app and to process creation of the token
   * directly in the app.
   */
  protected async getAuthTokenRequest(): Promise<AuthTokenRequest | undefined> {
    const authProviderId = this.getAuthProviderId();
    const verifierCode = await this.getVerifierCode();
    const code = this.activatedRoute.snapshot.queryParams.code;
    const app_redirect = this.activatedRoute.snapshot.queryParams.app_redirect;

    if (!authProviderId || !(authProviderId in AuthProvider) || code.length === 0) {
      if (isDevMode()) {
        console.error('authProvider and authCode');
      }

      return;
    }

    // Handles TParty providers oauth login.
    // If the user uses some of the TParty providers to login we force the browser to open the app
    // When app_redirect is updated from the 'appUrlOpen' handler to true, process can continue as normal (Eduspire SSO) login
    if (authProviderId !== AuthProvider.Eduspire && !app_redirect) {
      this.goTo('app');
      this.showUseWebBrowserButton = true;
    } else {
      // Handles when the app make internal redirecting (deep link) by url scheme
      return {
        code,
        authProviderId,
        codeVerifier: verifierCode,
        redirectUri: this.redirectUri
      };
    }

    return undefined;
  }

  protected get redirectUri(): string {
    const authProviderId = this.getAuthProviderId();

    if (authProviderId === AuthProvider.Eduspire) {
      return `/${AppRoute.AuthCallBackMobile}`;
    } else {
      // If the SSO isn't Eduspire the redirectUri should contains full path.
      // (Providers as Google, Microsoft etc. supports only valid url scheme for a redirectUri)
      return `${environment.domainUrl}/${this.activatedRoute.snapshot.routeConfig?.path}`;
    }
  }
}
