import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Injectable } from "@angular/core";
import { switchMap } from "rxjs/operators";
import { Observable, from,throwError } from "rxjs";
import { AuthFactoryService } from "../services/auth/auth-factory.service";
import { catchError } from 'rxjs/operators';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
    constructor(
        private authFactoryService: AuthFactoryService
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (
            environment.KeepLoggedIn &&
            (
                !request.url.includes('token/refresh') &&
                !request.url.includes('authcallback')
            ) &&
            this.authFactoryService.service.getToken() &&
            this.authFactoryService.service.isTokenExpired()
        ) {
            return from(this.authFactoryService.service.refreshToken())
                .pipe(switchMap((refreshed) => {
                    if (refreshed) {
                        return next.handle(request);
                      } else {
                        return throwError('Token refresh failed, handle logout or redirect');
                      }
                }),
                catchError(() => {
                    return next.handle(request); 
                  })
            
            )
        }

        return next.handle(request);
    }
}