import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { PlatformMobileService } from '../platform-mobile.service';

@Injectable({ providedIn: 'root' })
export class AndroidPlatformService extends PlatformMobileService {
  constructor(protected router: Router, protected zone: NgZone) {
    super(router, zone);
  }
}
