import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppConfig } from 'src/app/configs/app.config';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  constructor(private translateService: TranslateService) {
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translateService.setDefaultLang(AppConfig.Language.defaultLanguage);
  }

  async initializeLanguage(): Promise<any> {
    const targetLanguage = navigator.language;
    await this.setLanguage(targetLanguage);
  }

  async setLanguage(language: string): Promise<any> {
    // convert to short language code, en-US to en.
    language = language.substr(0, 2);

    if (!this.translateService.getLangs().includes(language)) {
      return;
    }

    await this.translateService.use(language).toPromise();
  }
}
