// tslint:disable unified-signatures

import { formatDate } from '@angular/common';
import { Inject, LOCALE_ID, Optional, Pipe, PipeTransform } from '@angular/core';
import { DATE_PIPE_DEFAULT_TIMEZONE } from './injection-tokens/date-pipe-default-timezone';
import { DateManagerService } from '../../services/date-manager/date-manager.service';

@Pipe({ name: 'extendedDate', pure: true })
export class ExtendedDatePipe implements PipeTransform {
  constructor(
    private dateManagerService: DateManagerService,
    @Inject(LOCALE_ID) private locale: string,
    @Inject(DATE_PIPE_DEFAULT_TIMEZONE) @Optional() private defaultTimezone?: string | null
  ) {}

  /**
   * @param value The date expression: a `Date` object,  a number
   * (milliseconds since UTC epoch), or an ISO string (https://www.w3.org/TR/NOTE-datetime).
   * @param format The date/time components to include, using predefined options or a
   * custom format string.
   * @param timezone A timezone offset (such as `'+0430'`), or a standard UTC/GMT, or continental US
   * timezone abbreviation. When not supplied, either the value of the `DATE_PIPE_DEFAULT_TIMEZONE`
   * injection token is used or the end-user's local system timezone.
   * @param locale A locale code for the locale format rules to use.
   * When not supplied, uses the value of `LOCALE_ID`, which is `en-US` by default.
   * See [Setting your app locale](guide/i18n-common-locale-id).
   * @returns A date string in the desired format.
   */
  transform(value: Date | string | number, format?: string, timezone?: string, locale?: string): string | null;
  transform(value: null | undefined, format?: string, timezone?: string, locale?: string): null;
  transform(
    value: Date | string | number | null | undefined,
    format?: string,
    timezone?: string,
    locale?: string
  ): string | null;
  transform(
    value: Date | string | number | null | undefined,
    format = 'mediumDate',
    timezone?: string,
    locale?: string
  ): string | null {
    if (value == null || value === '' || value !== value) {
      return null;
    }

    let currentTimezone = timezone ?? this.defaultTimezone ?? undefined;

    // If the date that should be formated has following format/value
    // 2022-02-02 -> YYYY-MM-DD
    // the currentTimezone should not be used, otherwise date that comes
    // from the API as 2022-02-02 (without time) is calculated to 2022-02-01
    // (one day in the past, this depends from the timezone.The example is for UTC-6)
    if (this.isDateFormat_YYYY_MM_DD(value) || this.ignoreTimezone(timezone)) {
      currentTimezone = undefined;
    }

    if (currentTimezone) {
      value = this.dateManagerService.getDST(value);
    }

    try {
      return formatDate(value, format, locale || this.locale, currentTimezone);
    } catch (error: any) {
      throw this.invalidPipeArgumentError(ExtendedDatePipe, error.message);
    }
  }

  isDateFormat_YYYY_MM_DD(value: any): boolean {
    return /\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/g.exec(value) != null;
  }

  invalidPipeArgumentError(type: any, value: any) {
    return Error(`InvalidPipeArgument: '${value}' for pipe '${JSON.stringify(type)}'`);
  }

  ignoreTimezone(timezone: string | undefined): boolean {
    return (timezone === 'ignore');
  }
}
