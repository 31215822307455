import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';

import { PlatformAbstractService } from '../platform-abstract.service';

@Injectable({ providedIn: 'root' })
export class DesktopPlatformService extends PlatformAbstractService {
  constructor(protected router: Router, protected zone: NgZone) {
    super(router, zone);
  }

  init(): void {}

  hookDeepLinks(): void {}
}
