import { Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';
import { AppRoute } from 'src/app/constants/app.route';
import { environment } from 'src/environments/environment';
import { AuthFactoryService } from '../services/auth/auth-factory.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanLoad {
  constructor(private router: Router, private authFactoryService: AuthFactoryService) {}

  async canLoad(): Promise<boolean> {
    if (this.authFactoryService.service.isTokenExpired()) {
      if (environment.KeepLoggedIn) {
        return await this.handleKeepLoggedInIsOn();
      } else {
        this.handleKeepLoggedInIsOff();
      }

      return false;
    }

    return true;
  }

  private handleKeepLoggedInIsOff(): void {
    this.authFactoryService.service.clear();
    this.router.navigateByUrl(AppRoute.NoAccess);
  }

  private async handleKeepLoggedInIsOn(): Promise<boolean> {
    if (!(await this.authFactoryService.service.refreshToken())) {
      this.authFactoryService.service.clear();
      this.router.navigateByUrl(AppRoute.NoAccess);
      return false;
    }

    return true;
  }
}
