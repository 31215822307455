import { Injectable } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ObservableWrapperService {
  private subscription: Subscription | undefined;

  unsubscribeWrapper<T>(method: Observable<T>, destroyNotify: Subject<unknown>, callback: (res: T) => void) {
    this.subscription?.unsubscribe();
    this.subscription = method.pipe(takeUntil(destroyNotify)).subscribe(res => {
      if (callback) {
        callback(res);
      }
    });
  }
}
