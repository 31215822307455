import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppRoute } from 'src/app/constants/app.route';
import { Authority } from 'src/app/constants/authority';
import { assignLocation, parseJsonTo } from '../../utils/utils';
import { School } from '../http/school/interfaces/school';
import { User } from '../http/user/interfaces/user';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { AuthService } from './../http/auth/auth.service';
import { TokenStorageService } from '../auth/token-storage.service';

@Injectable({
  providedIn: 'root'
})
export abstract class AuthAbstractService {
  private assignLocation = assignLocation;

  constructor(
    protected localStorageService: LocalStorageService,
    protected authService: AuthService,
    protected tokenStorageService: TokenStorageService,
    protected router: Router
  ) {}

  setVerifierCode(verifierCode: string): void {
    this.localStorageService.setItem('verifierCode', verifierCode);
  }

  getVerifierCode(): string {
    return this.localStorageService.getItem('verifierCode');
  }

  removeVerifierCode(): void {
    this.localStorageService.removeItem('verifierCode');
  }

  isTokenExpired(): boolean {
    const token = this.getToken();

    if (!token) {
      return true;
    }

    const tokenExpirationDate = this.getTokenExpirationDate();

    if (!tokenExpirationDate) {
      return true;
    }

    return new Date() > tokenExpirationDate;
  }

  hasAnyAuthority(authority: Authority[]): boolean {
    // If we don't have defined any authority, we process request without checking of the rights
    if (!authority || authority.length === 0) {
      return true;
    }

    const schoolUserAuthority = this.getSchoolUserAuthority();

    if (!schoolUserAuthority) {
      return false;
    }

    return authority && authority.includes(schoolUserAuthority?.role);
  }

  redirectToLogin(): void {
    this.assignLocation(AppRoute.Login);
  }

  getSchoolUtcOffsetInSec(): number | undefined {
    const schoolUserAuthority = this.getSchoolUserAuthority();

    if (!schoolUserAuthority) {
      return;
    }

    return schoolUserAuthority.timeZone.l10nDateTimeUtcOffsetSeconds;
  }

  getSchooTimeZoneCode(): string | undefined {
    const schoolUserAuthority = this.getSchoolUserAuthority();

    if (!schoolUserAuthority) {
      return;
    }

    return schoolUserAuthority.timeZone.name;
  }

  getAuthenticatedUser(): HttpResponse<User> | null {
    return parseJsonTo<HttpResponse<User>>(this.localStorageService.getItem('authenticatedUser'));
  }

  abstract setSchoolUserAuthority(school: School | Authority): void;
  abstract getSchoolUserAuthority(): School | null;
  abstract setUserData(value: any): Promise<void>;
  abstract getToken(): string;
  abstract getTokenExpirationDate(): Date | undefined;
  abstract getUser(): User | null;
  abstract refreshToken(): Promise<boolean>;
  abstract clear(): void;
  abstract logOut(): void;
}
