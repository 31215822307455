import { formatDate } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { isDevMode } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { Params } from '@angular/router';
import { Base64 } from 'js-base64';
import { sha256 } from 'js-sha256';
import getPkce from 'oauth-pkce';
import { merge, Observable, throwError } from 'rxjs';
import { AppConfig } from 'src/app/configs/app.config';
import { AppRoute } from 'src/app/constants/app.route';
import { Status } from '../services/http/general/enums/status';

export function clearFormControlsValues(controls: string[], form?: UntypedFormGroup, emitEvent = true): void {
  controls.forEach(controlName => form?.get(controlName)?.setValue(null, { emitEvent }));
}

export function clearFormControlsValuesByFormGroup(
  form?: UntypedFormGroup,
  emitEvent = true,
  shouldResetDisabled = true
): void {
  for (const controlName in form?.controls) {
    if (Object.prototype.hasOwnProperty.call(form?.controls, controlName)) {
      const control = form?.get(controlName);

      if (!control) {
        continue;
      }

      if (!shouldResetDisabled && control.disabled) {
        continue;
      }

      control.setValue(null, { emitEvent });
    }
  }
}

export function clearFormControlsValueWithoutExcluded(
  excludedControls: string[],
  form?: UntypedFormGroup,
  emitEvent = true,
  shouldResetDisabled = true
): void {
  for (const controlName in form?.controls) {
    if (Object.prototype.hasOwnProperty.call(form?.controls, controlName)) {
      if (excludedControls.indexOf(controlName) === -1) {
        const control = form?.get(controlName);

        if (!control) {
          continue;
        }

        if (!shouldResetDisabled && control.disabled) {
          continue;
        }

        control.setValue(null, { emitEvent });
      }
    }
  }
}

export function parseJsonTo<T>(json: string): T | null {
  if (json.length === 0) {
    return null;
  }

  return JSON.parse(json) as T;
}

export function getFormControlsByNames(names: string[], form?: UntypedFormGroup): Record<string, AbstractControl> | undefined {
  if (!form) {
    return;
  }

  const formControls: Record<string, AbstractControl> = {};

  for (const controlName in form.controls) {
    if (Object.prototype.hasOwnProperty.call(form.controls, controlName)) {
      if (names.indexOf(controlName) !== -1) {
        formControls[controlName] = form.controls[controlName];
      }
    }
  }

  return formControls;
}

export function clearFormControlsValidators(controls: string[], form?: UntypedFormGroup): void {
  controls.forEach(controlName => {
    form?.get(controlName)?.clearValidators();
    form?.get(controlName)?.updateValueAndValidity({ emitEvent: false });
  });
}

export function clearAllFormControlsValidators(form: UntypedFormGroup): void {
  for (const key in form.controls) {
    if (form.get(key)) {
      form.get(key)?.clearValidators();
      form.get(key)?.updateValueAndValidity({ emitEvent: false });
    }
  }

  form.clearValidators();
}

export function trackBy(item: any): any {
  return item.id || item.uuid || item.name;
}

export function stringToSHA256(value: string): string {
  return sha256(value);
}

export function stringToBase64(value: string): string {
  if (!value) {
    return '';
  }

  return Base64.encode(value);
}

export function base64ToString(value: string): string {
  if (!value) {
    return '';
  }

  return Base64.decode(value);
}

export function showLoadingOnDataTable(component: any, loadingState: boolean): void {
  if ('isLoading' in component) {
    component.isLoading = loadingState;
  } else {
    if (isDevMode()) {
      console.error('There is no define isLoading class member, please implement DataTableLoadingUpdater');
    }

    return;
  }
}

export function getFileContent(file: File): Promise<string> {

  return new Promise((resolve, reject) => {
    if (!file) {
      resolve('');
      return;
    }

    let fileReader = new FileReader();

    // Is is a "real" file (not the one overriden by cordova-plugin-file), then we need to use the "real" FileReader.
    if (file instanceof Blob) {
      const realFileReader = (fileReader as any)._realReader;
      if (realFileReader) {
        fileReader = realFileReader;
      }
    }

    fileReader.onload = () => {
      resolve(fileReader.result as string);
    };

    fileReader.onerror = () => {
      return reject(fileReader.error);
    };

    fileReader.readAsText(file);
  });
}

export function downloadFile(content: any, filename: string, contentType = 'text/plain'): void {
  if (!contentType) {
    contentType = 'application/octet-stream';
  }

  const a = document.createElement('a');
  const blob = new Blob([content], { type: contentType });
  a.href = window.URL.createObjectURL(blob);
  a.download = filename;
  a.click();
  a.remove();
}

export function getTimeByUTCShift(shift: number): Date {
  const date = new Date();
  date.setHours(0, 0, 0, 0);
  date.setSeconds(shift);

  return date;
}

export function getSecondsByUTCTime(date: Date): number {
  if (!date) {
    return 0;
  }

  const todayDate = new Date();
  todayDate.setHours(0, 0, 0, 0);

  return Math.round((date.getTime() - todayDate.getTime()) / 1000);
}

export function convertWeekNumbersToDays(weekNumbers: number[]): string {
  if (!weekNumbers) {
    return '';
  }

  const daysOfTheWeek: string[] = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI'];

  return weekNumbers
    .map(day => {
      return daysOfTheWeek[day];
    })
    .join(',');
}

export function mergeValueChangesByFormControlName(names: string[], form: UntypedFormGroup): Observable<any> {
  const observables: Observable<any>[] = [];

  names.forEach(name => {
    const control = form.get(name);

    if (control) {
      observables.push(control.valueChanges);
    }
  });

  return merge(...observables);
}

export function mergeValueChangesByFormControlWithoutExcluded(
  excludedControls: string[],
  form: UntypedFormGroup
): Observable<any> {
  const observables: Observable<any>[] = [];

  for (const controlName in form?.controls) {
    if (Object.prototype.hasOwnProperty.call(form?.controls, controlName)) {
      if (excludedControls.indexOf(controlName) === -1) {
        observables.push(form?.controls[controlName].valueChanges);
      }
    }
  }

  return merge(...observables);
}

export function convertToInt(value: any): number {
  return parseInt(value, 0);
}

export function disableFormGroup(arg: { shouldDisableForm: boolean; form?: UntypedFormGroup }): void {
  if (!arg || !arg.form) {
    return;
  }

  if (arg.shouldDisableForm === true) {
    arg.form.disable({ emitEvent: false });
  } else {
    arg.form.enable({ emitEvent: false });
  }

  arg.form.updateValueAndValidity();
}

export async function generateCodeChallenge(): Promise<{ verifier: string; challenge: string }> {
  return await new Promise(resolve => {
    getPkce(43, (error, { verifier, challenge }) => {
      if (error) {
        throw throwError(error);
      }
      resolve({ verifier, challenge });
    });
  });
}

export function stringToDate(str?: string): Date | undefined {
  if (!str) {
    return undefined;
  }

  if (!str || str.length === 0) {
    return;
  }

  return new Date(str);
}

// @ts-ignore
export function groupBy<T>(xs, f): T {
  // @ts-ignore
  return xs.reduce((r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r), {});
}

export function isWorkDay(day: number): boolean {
  return day !== 0 && day !== 6;
}

export function toParams(requestType?: any, excludedParamsKeys?: string[]): HttpParams {
  let params = new HttpParams();

  if (!requestType) {
    return params;
  }

  for (const key in requestType) {
    if (Object.prototype.hasOwnProperty.call(requestType, key)) {
      if (excludedParamsKeys?.includes(key)) {
        continue;
      }

      const value = requestType[key];
      params = params.append(key, String(value));
    }
  }

  return params;
}

export function assignLocation(route: AppRoute): void {
  location.assign(route);
}

export function isDatesEqual(firstDate: Date, secondDate: Date): boolean {
  return (
    firstDate.getFullYear() === secondDate.getFullYear() &&
    firstDate.getMonth() === secondDate.getMonth() &&
    firstDate.getDate() === secondDate.getDate()
  );
}

export function compareDateByDays(firstDate?: Date, secondDate?: Date): 1 | -1 | 0 | null {
  let result: 1 | -1 | 0 | null = null;

  if (!firstDate || !secondDate) {
    return result;
  }

  firstDate.setHours(0, 0, 0, 0);
  secondDate.setHours(0, 0, 0, 0);

  const diffTime = firstDate.getTime() - secondDate.getTime();
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  if (diffDays > 0) {
    result = 1;
  } else if (diffDays < 0) {
    result = -1;
  } else if (diffDays === 0) {
    result = 0;
  }

  return result;
}

export function getRandomString(maxLength = 5): string {
  return Math.random().toString(20).substr(2, maxLength);
}

export function toggleStatus<T extends { status: Status }>(obj: T): T {
  const objCloned = { ...obj };

  objCloned.status = obj.status === Status.Active ? Status.Inactive : Status.Active;

  return objCloned;
}

export function removeDublicateItems<T>(items: T[]): T[] {
  return [...new Set(items)];
}

export function queryParamsTo<T>(queryParams: Params): T | undefined {
  const t = queryParams as any;

  if (Object.keys(t).length > 0) {
    return t;
  }

  return undefined;
}

export function formatDateToApiFormat(date: string | Date): string {
  return formatDate(date, AppConfig.Formats.DateApiFormat, AppConfig.Language.defaultLocale);
}

export function formatDateTimeToISO8601WithoutMs(date: string | Date): string {
  return new Date(date).toISOString().split('.')[0] + 'Z';
}

export function toLink(value: string, target?: '_blank', overwriteTarget = true): string {
  let result = value.replace(/href="(?!http)/g, 'href="https://');

  function addTarget(html: string) {
    const domParser = new DOMParser();
    const document = domParser.parseFromString(html, `text/html`);
    const serializer = new XMLSerializer();

    const links = document.querySelectorAll(`a`);

    links.forEach(link => {
      if (link.href && target && overwriteTarget) {
        link.target = target;
      } else if (link.href && target && !link.target) {
        link.target = target;
      }
    });

    return serializer
        .serializeToString(document)
        .replace('<html xmlns="http://www.w3.org/1999/xhtml"><head></head><body>', '')
        .replace('</body></html>', '');
  }

  if (target) {
    result = addTarget(result);
  }

  return result;
}

export function isFeatureEnabled(name: string): boolean {
  // @ts-ignore
  if (AppConfig.Features.hasOwnProperty(name) && !AppConfig.Features[name]) {
    return false;
  }

  return true;
}

export function handleTooltipPositionOnCursorMove(event: MouseEvent):void {
  const tooltip = document.getElementById('chartjs-tooltip');
  if (!tooltip) {
    return;
  }
  tooltip.style.left = event.pageX + 20 + 'px';
  tooltip.style.top = event.pageY + 20 + 'px';
}
