import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { AndroidPlatformService } from './android/android-platform.service';
import { DesktopPlatformService } from './desktop/desktop-platform.service';
import { IosPlatformService } from './ios/ios-platform.service';
import { PlatformMobileService } from './platform-mobile.service';
import { PlatformDevice } from './platform-device';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class PlatformFactoryService {
  platformDevice: PlatformDevice | undefined;

  constructor(
    private platform: Platform,
    private androidPlatformService: AndroidPlatformService,
    private iosPlatformService: IosPlatformService,
    private desktopPlatformService: DesktopPlatformService,
    private platformMobileService: PlatformMobileService
  ) { }

  initializePlatform(): Promise<PlatformDevice> {
    return new Promise<PlatformDevice>(resolve => {
      this.platform.ready().then(() => {
        if (environment.mobile) {
          if (this.platform.is('android')) {
            this.androidPlatformService.init();
            this.platformDevice = this.androidPlatformService;
          } else if (environment.mobile && this.platform.is('ios')) {
            this.iosPlatformService.init();
            this.platformDevice = this.iosPlatformService;
          } else {
            this.platformMobileService.init();
            this.platformDevice = this.platformMobileService;
          }
        } else {
          this.platformDevice = this.desktopPlatformService;
        }

        resolve(this.platformDevice);
      });
    });
  }
}
