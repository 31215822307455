import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppConfig } from 'src/app/configs/app.config';
import { EventType } from 'src/app/constants/events-type';
import { EventManagerService } from 'src/app/core/services/event-manager/event-manager.service';
import { EventContent } from 'src/app/core/services/event-manager/models/event-content';

@Component({
  selector: 'app-preloader',
  templateUrl: './preloader.component.html',
  styleUrls: ['./preloader.component.scss']
})
export class PreloaderComponent implements OnInit, OnDestroy {
  private subscription: Subscription | undefined;
  shouldShowPreloader = false;
  debounce: any;

  constructor(private eventManager: EventManagerService) {}

  ngOnInit(): void {
    this.subscription = this.eventManager.subscribe(EventType.PreloaderShow, (eventContent: EventContent<boolean>) => {
      this.onPreloaderVisibility(eventContent);
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.eventManager.destroy(this.subscription);
    }
  }

  private onPreloaderVisibility(eventContent: EventContent<boolean>): void {
    // Prevents further executution if there is no actual change
    if (this.shouldShowPreloader === eventContent.content) {
      return;
    }

    if (eventContent.content === false) {
      clearTimeout(this.debounce);

      this.debounce = setTimeout(() => {
        this.shouldShowPreloader = eventContent.content;
      }, AppConfig.Preloader.debounceTimeMs);
    } else {
      this.shouldShowPreloader = eventContent.content;
    }
  }
}
