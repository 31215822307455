import { HttpEvent, HttpHandler, HttpInterceptor, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ExtendedDate } from '../services/date-manager/classes/extended-date';
import { DateManagerService } from '../services/date-manager/date-manager.service';

@Injectable()
export class ParamValidatorInterceptor implements HttpInterceptor {
  constructor(private dateManagerService: DateManagerService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const params = this.filterNullableParams(request);
    const body = this.filterNullableBodyParams(request);

    const requestCloned = request.clone({ params, body });
    return next.handle(requestCloned);
  }

  private filterNullableBodyParams(request: HttpRequest<any>): Record<any, any> {
    let body: Record<any, any> = {};

    if (this.isValueNotArray(request.body)) {
      for (const key in request.body) {
        if (Object.prototype.hasOwnProperty.call(request.body, key)) {
          let value = request.body[key];

          if (this.isParamNotEmpty(value)) {
            value = this.formatDateParam(key, value);
            body[key] = value;
          }
        }
      }
    } else {
      body = request.body;
    }

    return body;
  }

  private filterNullableParams(request: HttpRequest<any>): HttpParams {
    let params: HttpParams = new HttpParams();

    for (const key of request.params.keys()) {
      let value = request.params.get(key);

      if (value !== null && this.isParamNotEmpty(value)) {
        value = this.formatDateParam(key, value);
        params = params.append(key, String(value));
      }
    }

    return params;
  }

  private formatDateParam(key: string, value: any): string {
    if (this.isParamNameDefinedAsDate(key)) {
      if (this.isValueNotArray(value)) {
        if (this.isDate(value)) {
          return this.dateManagerService.dateToString(value);
        }
      } else {
        for (let index = 0; index < value.length; index++) {
          if (this.isDate(value[index])) {
            value[index] = this.dateManagerService.dateToString(value[index]);
          }
        }

        return value;
      }
    }

    return value;
  }

  private isParamNotEmpty(value: any): boolean {
    return value !== '' && value !== 'null' && value !== 'undefined' && value !== undefined;
  }

  private isParamNameDefinedAsDate(key: any): boolean {
    return key.toLowerCase().endsWith('date') || key.toLowerCase().endsWith('dates');
  }

  private isDate(value: any): boolean {
    if (typeof value === 'string' || value instanceof Date || value instanceof ExtendedDate) {
      return true;
    }

    return false;
  }

  private isValueNotArray(value: any): boolean {
    return !Array.isArray(value);
  }
}
