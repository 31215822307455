import { Injectable } from '@angular/core';
import { AppConfig } from 'src/app/configs/app.config';
import { AuthImpersonateUserService } from 'src/app/core/services/auth/auth-impersonate-user.service';
import { AuthUserService } from 'src/app/core/services/auth/auth-user.service';
import { User } from 'src/app/core/services/http/user/interfaces/user';

@Injectable({
  providedIn: 'root'
})
export class SimulateService {
  constructor(
    private authImpersonateUserService: AuthImpersonateUserService,
    private authUserService: AuthUserService
  ) {}

  /**
   * Creates impersonate data in the localStorage/sessionStorage
   * Redirect user to the default route by his role
   */
  async impersonate(user: User): Promise<void> {
    if (!user || !user.role?.code) {
      return;
    }

    await this.authImpersonateUserService.setUserData(user);

    const defaultRoute = AppConfig.DefaultRouteUserRoleMapping[user.role?.code];

    if (defaultRoute) {
      location.assign(defaultRoute);
    }
  }

  return(): void {
    this.authImpersonateUserService.clear();
    const schoolUserAuthority = this.authUserService.getSchoolUserAuthority();

    if (schoolUserAuthority) {
      const defaultRoute = AppConfig.DefaultRouteUserRoleMapping[schoolUserAuthority.role];

      if (defaultRoute) {
        location.assign(defaultRoute);
      }
    }
  }
}
