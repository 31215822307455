import { NgModule } from '@angular/core';
import { HasAnyAuthorityDirective } from '../core/directives/auth/has-any-authority.directive';
import { AutocompleteOffDirective } from '../core/directives/autocomplete-off/autocomplete-off.directive';
import { BtnDarkClassDirective } from '../core/directives/btn-dark-class/btn-dark-class.directive';
import { DelayRenderingDirective } from '../core/directives/delay/delay-rendering.directive';
import { DelayShowingDirective } from '../core/directives/delay/delay-showing.directive';
import { ParentValidatorDirective } from '../core/directives/parent-validator/parent-validator.directive';
import { PriorityClassDirective } from '../core/directives/priority-class/priority-class.directive';
import { ScheduledClassDirective } from '../core/directives/scheduled-class/scheduled-class.directive';
import { StatusClassDirective } from '../core/directives/status-class/status-class.directive';
import { VisibilityByDeviceDirective } from '../core/directives/visibility-by-device/visibility-by-device.directive';
import { ClassByAuthorityDirective } from '../core/directives/visiblity-by-authority/class-by-authority.directive';
import { SchoolYearDisabledActionDirective } from '../core/directives/school-year-disabled-action/school-year-disabled-action.directive';
import { FeatureFlagHiddenDirective } from '../core/directives/feature-flag-hidden/feature-flag-hidden.directive';
import { TooltipDirective } from '../core/directives/tooltip/tooltip.directive';

@NgModule({
  declarations: [
    HasAnyAuthorityDirective,
    DelayRenderingDirective,
    DelayShowingDirective,
    AutocompleteOffDirective,
    ParentValidatorDirective,
    ClassByAuthorityDirective,
    VisibilityByDeviceDirective,
    PriorityClassDirective,
    ScheduledClassDirective,
    StatusClassDirective,
    BtnDarkClassDirective,
    SchoolYearDisabledActionDirective,
    FeatureFlagHiddenDirective,
    TooltipDirective
  ],
  exports: [
    HasAnyAuthorityDirective,
    DelayRenderingDirective,
    DelayShowingDirective,
    AutocompleteOffDirective,
    ParentValidatorDirective,
    ClassByAuthorityDirective,
    VisibilityByDeviceDirective,
    PriorityClassDirective,
    ScheduledClassDirective,
    StatusClassDirective,
    BtnDarkClassDirective,
    SchoolYearDisabledActionDirective,
    FeatureFlagHiddenDirective,
    TooltipDirective
  ]
})
export class DirectivesModule {}
