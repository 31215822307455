import { ActivatedRoute, Router } from '@angular/router';
import { Component } from '@angular/core';
import { AuthUserService } from 'src/app/core/services/auth/auth-user.service';
import { AuthCallBackComponent } from '../auth-call-back/auth-call-back.component';
import { generateCodeChallenge } from '../../utils/utils';

@Component({
  template: ''
})
export class ProviderAuthCallBackComponent extends AuthCallBackComponent {
  constructor(
    activatedRoute: ActivatedRoute,
    authUserService: AuthUserService,
    router: Router
  ) {
    super(activatedRoute, authUserService, router);
  }

  protected getAuthProviderId(): number | void {
    return this.activatedRoute.snapshot.data.provider;
  }

  protected async getVerifierCode() {
    const { verifier } = await generateCodeChallenge();

    return verifier;
  }
}
