import { Injectable } from '@angular/core';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { AuthAbstractService } from './auth-abstract.service';
import { AuthImpersonateUserService } from './auth-impersonate-user.service';
import { AuthUserService } from './auth-user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthFactoryService {
  service: AuthAbstractService = this.authUserService;

  constructor(
    private authImpesonateUserService: AuthImpersonateUserService,
    private authUserService: AuthUserService,
    private localStorageService: LocalStorageService
  ) {
    if (this.isImpersonate()) {
      this.service = this.authImpesonateUserService;
    }
  }

  private isImpersonate(): boolean {
    return this.localStorageService.getItem('userImpersonate').length > 0;
  }
}
