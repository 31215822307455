import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { datadogRum } from '@datadog/browser-rum';
import { Platform } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { Authority } from './constants/authority';
import { PlatformFactoryService } from './core/services/platform/platform-factory.service';
import { TranslationService } from './core/services/translate/translation.service';
import { PushSubscriberService } from './core/services/notification/push/push-subscriber.service';
import { LiveNotificationService } from './core/services/notification/live-notification.service';
import { AuthenticatedUserService } from './core/services/http/authenticated-user/authenticated-user.service';
import { User } from './core/services/http/user/interfaces/user';
import { isFeatureEnabled } from './core/utils/utils';

declare let gtag: (arg1: string, arg2: string, arg3: { page_path: string }) => void;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  authority = Authority;
  simulateClass: string | undefined;
  platformClass: string | undefined;

  constructor(
    public platformFactoryService: PlatformFactoryService,
    private translationService: TranslationService,
    private cdRef: ChangeDetectorRef,
    private router: Router,
    private platform: Platform,
    private pushSubscriberService: PushSubscriberService,
    private liveNotificationService: LiveNotificationService,
    private authenticatedUserService: AuthenticatedUserService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.translationService.initializeLanguage();
    this.initTPartyProviders();
    (await this.platformFactoryService.initializePlatform()).platformClassChange?.subscribe(platformClass => {
      this.platformClass = platformClass;
      this.detectChanges();
    });

    if (isFeatureEnabled('notifications')) {
      this.authenticatedUserService.authenticatedUserChanged.subscribe((currentUser: User | undefined) => {
        if (currentUser !== undefined) {
          this.liveNotificationService.subscribe();
          this.pushSubscriberService.subscribe();
        }
      });
    }
  }

  onSimulateClassChange(simulateClass: string): void {
    this.simulateClass = simulateClass;
    this.detectChanges();
  }

  // @todo check for alternative way to fix ExpressionChangedAfterItHasBeenCheckedError
  private detectChanges(): void {
    this.cdRef.detectChanges();
  }

  private initDataDog(): void {
    if (environment.production) {
      datadogRum.init(environment.DataDogConfig);
    }
  }

  private initGoogleAnalytics(): void {
    if (environment.production) {
      // subscribe routing events to call the Google Analytics gtag()
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          gtag('config', 'UA-32101565-4', { page_path: event.urlAfterRedirects });
        }
      });
    }
  }

  private initTPartyProviders(): void {
    this.platform.ready().then(() => {
      if (this.platform.is('mobileweb') || this.platform.is('desktop')) {
        this.initDataDog();
        this.initGoogleAnalytics();
      }
    });
  }
}
