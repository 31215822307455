export enum AuthProvider {
  Eduspire = 1,
  Google = 10,
  Microsoft = 20,
  Aeries = 100,
  ClassLink = 101,
  Clever = 102,
  GG4L = 103,
  Edlink = 104
}
