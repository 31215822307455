import { Injectable } from '@angular/core';
import { RestGeneric } from '../rest.generic';
import { Notification } from './interfaces/notification';
import { HttpClient } from '@angular/common/http';
import { AuthFactoryService } from '../../auth/auth-factory.service';
import { RestGenericUtilService } from '../rest-generic-util.service';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { Paging } from '../general/classes/paging';
import { NotificationDeviceSubscription } from './interfaces/notification-device-subscription';

@Injectable({
    providedIn: 'root'
})
export class NotificationService extends RestGeneric<any, Notification, any> {
    protected baseApiUrl = environment.EduSpireApi.domainNotificationsUrl;
    constructor(
        protected http: HttpClient,
        protected authFactoryService: AuthFactoryService,
        protected restGenericUtilService: RestGenericUtilService
    ) {
        super(http, authFactoryService, 'me/notifications', restGenericUtilService);
    }

    public getMyNotifications(paging: Paging): Observable<Array<Notification>> {
        const params = this.restGenericUtilService.toParams({ ...paging.getApiPaginationData() });
        return this.http.get<Array<Notification>>(
            `${this.baseApiUrl}/${this.endpointName}`,
            {
                params
            }
        );
    }

    public getUnreadCount(): Observable<{ unreadCount: number }> {
        return this.http.get<{unreadCount: number}>(
            `${this.baseApiUrl}/${this.endpointName}/unread-count`,
        );
    }

    public markAsRead(notification: Notification): Observable<any> {
        return this.http.put<any>(
            `${this.baseApiUrl}/${this.endpointName}/${notification.uuid}/mark-as-read`,
            {}
        );
    }

    public markAllAsRead(): Observable<any> {
        return this.http.patch<any>(
            `${this.baseApiUrl}/${this.endpointName}/mark-as-read`,
            {}
        );
    }

    public subscribeForDeviceNotifications(subscription: NotificationDeviceSubscription): Observable<any> {
        return this.http.post<NotificationDeviceSubscription>(
            `${this.baseApiUrl}/${this.endpointName}/device-subscriptions`,
            subscription
        );
    }
}
