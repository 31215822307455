<alert
  *ngIf="alert"
  (onClose)="onClose()"
  [dismissible]="alert.dismissible || false"
  [dismissOnTimeout]="alert.dismissOnTimeout || appConfig.Alert.dismissOnTimeout"
  type="{{ alert.type }}"
  class="server-alert {{ alert.position }}"
>
  <div class="alert-body">
    {{ alert.message }}
    <p *ngIf="alert.messageDetail">
      <small>{{ alert.messageDetail }}</small>
    </p>
  </div>
</alert>
