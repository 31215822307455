import { Component, Input, OnInit } from '@angular/core';
import { Notification } from '../../core/services/http/notification/interfaces/notification';
import { NotificationType } from '../../core/services/http/notification/interfaces/notification-type.enum';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { NotificationService } from '../../core/services/http/notification/notification.service';

@Component({
    selector: 'app-notification-link',
    templateUrl: './notification-link.component.html',
    styles: [
        `a { cursor: pointer; text-decoration: none}`
    ]
})
export class NotificationLinkComponent implements OnInit {
    @Input() public notification: Notification | undefined;

    public linkText = 'Link';
    public routerLink = '/';
    public queryParams = {};
    public constructor(
        private translateService: TranslateService,
        private notificationService: NotificationService,
        private router: Router
    ) {}

    async ngOnInit() {
        switch (this.notification?.notificationType.id) {
            case NotificationType.STUDENT_SCHEDULE_CHANGE_PRIORITY_ID :
            case NotificationType.TEACHER_UNSCHEDULED_ACTIVITY_ID:
            case NotificationType.STUDENT_REMOVED_FROM_ACTIVITY_ID:
            case NotificationType.SCHEDULED_ACTIVITY_MOVED_ID:
            case NotificationType.SCHEDULED_ACTIVITY_ROOM_CHANGED_ID:
            case NotificationType.SCHEDULED_ACTIVITY_TITLE_OR_DESCRIPTION_CHANGED_ID:
            case NotificationType.LUNCH_REGISTRATION_CREATED_ID:
            case NotificationType.LUNCH_REGISTRATION_REMOVED_ID:
            case NotificationType.ROLLBACK_TO_CATCHALL_ID:
            case NotificationType.ROLLBACK_TO_ACTIVITY:
            case NotificationType.STUDENT_AGENDA_ID:
                this.queryParams = {calendarDate: this.notification?.params.scheduled_date, tab: 1};
                this.routerLink = '/schedule';
                this.linkText = await this.translateService.get('VIEW_SCHEDULE').toPromise();
                break;
            case NotificationType.FLEX_PERIOD_CANCELLATION_CREATED_ID:
            case NotificationType.FLEX_PERIOD_CANCELLATION_UPDATED_ID:
            case NotificationType.FLEX_PERIOD_CANCELLATION_REMOVED_ID:
                this.queryParams = {calendarDate: this.notification?.params.cancellation_date, tab: 1};
                this.routerLink = '/schedule';
                this.linkText = await this.translateService.get('VIEW_SCHEDULE').toPromise();
                break;
            case NotificationType.STUDENT_RESTRICTION_CREATED_ID:
            case NotificationType.STUDENT_RESTRICTION_REMOVED_ID:
            case NotificationType.STUDENT_RESTRICTION_NOTE_CREATED_BY_STUDENT:
            case NotificationType.STUDENT_RESTRICTION_NOTE_CREATED_BY_ADULT:
            case NotificationType.TEACHER_RESTRICTION_NOTE_CREATED:
                this.routerLink = '/restricted-students';
                this.linkText = await this.translateService.get('VIEW_RESTRICTION').toPromise();
                break;
            case NotificationType.ATTENDANCE_REMINDER_ID:
                this.routerLink = '/student-attendance';
                this.linkText = await this.translateService.get('SUBMIT_ATTENDANCE').toPromise();
                break;
            case NotificationType.ATTENDANCE_REPORT_ID:
                this.routerLink = '/attendance-activity';
                this.linkText = await this.translateService.get('ATTENDANCE_REPORT').toPromise();
                break;
        }
    }

    public onLinkClick(): void {
        if (this.notification) {
            this.notificationService
                .markAsRead(this.notification)
                .subscribe(() => {
                    window.location.assign(
                        this.router.createUrlTree(
                            [this.routerLink],
                            {queryParams: this.queryParams}
                        ).toString()
                    );
                });
        }
    }
}
