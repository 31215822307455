import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthFactoryService } from '../services/auth/auth-factory.service';
import { AuthUserService } from '../services/auth/auth-user.service';
import { AuthService } from '../services/http/auth/auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
      private authFactoryService: AuthFactoryService,
      private authUserService: AuthUserService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let token = this.authFactoryService.service.getToken();

    if (request.url.endsWith(AuthService.SIMULATE_ENDPOINT)) {
      token = this.authUserService.getToken();
    }

    // Protects request Authorization overridding if we already have set Authorization token
    if (token && !request.headers.get('Authorization')) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }

    return next.handle(request);
  }
}
