import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtModule } from '@auth0/angular-jwt';
import { HTTP } from '@ionic-native/http/ngx';
import { IonicModule, Platform } from '@ionic/angular';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ChartsModule } from 'ng2-charts';
import { SharedModule } from 'src/app/shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthCallBackMobileComponent } from './core/component/auth-call-back-mobile/auth-call-back-mobile.component';
import { AuthCallBackComponent } from './core/component/auth-call-back/auth-call-back.component';
import { ProviderAuthCallBackComponent } from './core/component/provider-auth-callback/provider-auth-call-back.component';
import { CacheInterceptor } from './core/interceptors/cache.interceptor';
import { ErrorInterceptor } from './core/interceptors/error-interceptor';
import { ParamValidatorInterceptor } from './core/interceptors/param-validator-interceptor';
import { TokenInterceptor } from './core/interceptors/token.interceptor';
import { storageFactory } from './core/services/local-storage/storage-factory';
import { DATE_PIPE_DEFAULT_TIMEZONE } from './core/pipes/extended-date/injection-tokens/date-pipe-default-timezone';
import { AuthFactoryService } from './core/services/auth/auth-factory.service';
import { timeZoneFactory } from './core/time-zone-factory/time-zone.factory';
import { createTranslateLoader } from './core/translate-loader/translate.loader';
import { NoAccessComponent } from './no-access/no-access.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { SimulateModule } from './simulate/simulate.module';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { SchoolYearInterceptor } from './core/interceptors/school-year.interceptor';
import { RefreshTokenInterceptor } from "./core/interceptors/refresh-token.interceptor";
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';

import { environment } from '../environments/environment';

export const interceptorProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: RefreshTokenInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ParamValidatorInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: CacheInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: SchoolYearInterceptor,
    multi: true
  }
];

import { initializeApp } from 'firebase/app';

initializeApp(environment.Firebase);

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    NoAccessComponent,
    AuthCallBackComponent,
    AuthCallBackMobileComponent,
    ProviderAuthCallBackComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    JwtModule.forRoot({
      config: {
        allowedDomains: ['*'],
        tokenGetter: () => ''
      }
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    BrowserAnimationsModule,
    SharedModule,
    SimulateModule,
    ChartsModule,
    IonicModule.forRoot()
  ],
  providers: [
    interceptorProviders,
    HTTP,
    ScreenOrientation,
    FileOpener,
    { provide: Storage, useFactory: storageFactory, deps: [Platform] },
    { provide: DATE_PIPE_DEFAULT_TIMEZONE, useFactory: timeZoneFactory, deps: [AuthFactoryService] }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
