import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { EventType } from 'src/app/constants/events-type';
import { EventManagerService } from 'src/app/core/services/event-manager/event-manager.service';
import { AppConfig } from './../../configs/app.config';
import { EventContent } from './../../core/services/event-manager/models/event-content';
import { Alert } from './types/alert';

@Component({
  selector: 'app-message-alert',
  templateUrl: './alert-message.component.html',
  styleUrls: ['./alert-message.component.scss']
})
export class AlertMessageComponent implements OnInit, OnDestroy {
  alert: Alert | undefined;
  appConfig = AppConfig;

  private subscription: Subscription | undefined;

  constructor(private eventManager: EventManagerService, private translateService: TranslateService) {}

  ngOnInit(): void {
    this.subscription = this.eventManager.subscribe(EventType.AlertMessage, (eventContent: EventContent<Alert>) => {
      const alert: Alert = eventContent.content;

      this.updateAlert(alert);
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.eventManager.destroy(this.subscription);
    }
  }

  async updateAlert(alert: Alert): Promise<void> {
    this.alert = alert;

    alert.message = await this.translateService.get(alert.translateKey, alert.interpolateParams || {}).toPromise();

    if (this.alert.dismissOnTimeout === undefined) {
      this.alert.dismissOnTimeout = AppConfig.Alert.dismissOnTimeout;
    } else if (this.alert.dismissOnTimeout === 0) {
      this.alert.dismissible = true;
    }
  }

  onClose(): void {
    this.alert = undefined;
  }
}
