import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AppConfig } from 'src/app/configs/app.config';
import { AutoCompleteItem } from './interfaces/autocomplete-item';

@Component({
  selector: 'app-auto-complete',
  templateUrl: './auto-complete.component.html'
})
export class AutoCompleteComponent {
  items: AutoCompleteItem[] = [];
  @Input() set itemsData(items: AutoCompleteItem[]) {
    this.isLoading = false;

    if (!items) {
      return;
    }

    this.filterItemsByMultiple(items);
  }

  @Input() multiple = false;
  @Input() disabled = false;
  @Input() selectedItem: AutoCompleteItem | AutoCompleteItem[] | null | undefined;
  @Input() minTermLength: number = AppConfig.AutoComplete.minTermLength;
  @Input() searchOnClick = false;

  @Output() selectChange = new EventEmitter<AutoCompleteItem[]>();
  @Output() searchChange = new EventEmitter<string>();
  @Output() selectedItemChange = new EventEmitter<AutoCompleteItem>();
  @Output() removeItemChange = new EventEmitter<AutoCompleteItem[]>();

  isLoading = false;
  debounce: any;
  timeout: any;
  lastSentTerm: string | undefined;

  onChange(item: AutoCompleteItem): void {
    this.emitSelectChange([item]);
    this.emitSelectItemChange(item);
  }

  onSearch(item: { term: string; items: any[] }): void {
    clearTimeout(this.debounce);
    clearTimeout(this.timeout);

    if (item.term.length < this.minTermLength) {
      this.clearItems();
      return;
    }

    // Triggers select event after debounceTimeMs
    // Protects API from requesting a new data, every time when the user types a new symbol
    this.debounce = setTimeout(() => {
      // Prevents emitting of the event if the last send value is the same as current one
      // Example: If you type something and quickly deleted until debounceTimeMs.
      // This action wont be emitted because assigning of new value happend inside debounce.
      // This prevents API from additional requests that already is loaded
      if (this.lastSentTerm === item.term) {
        return;
      }

      this.isLoading = true;
      this.lastSentTerm = item.term;
      this.clearItems();

      this.searchChange.emit(item.term);
    }, AppConfig.AutoComplete.debounceTimeMs);

    // Hides loading after defined clearTimeOut
    // Protects UI from showing infinity loading state, if some error occurred
    this.timeout = setTimeout(() => {
      this.isLoading = false;
    }, AppConfig.AutoComplete.clearTimeOut);
  }

  onOpen(): void {
    this.clearItems();

    if (this.searchOnClick) {
      this.searchChange.emit();
    }
  }

  compareWith(item: AutoCompleteItem, selected: AutoCompleteItem): boolean {
    return item?.label?.toLowerCase() === selected?.label?.toLowerCase();
  }

  emitSelectChange(selectedItems: AutoCompleteItem[]): void {
    this.selectChange.emit(selectedItems);
  }

  emitSelectItemChange(selectedItem: AutoCompleteItem): void {
    this.selectedItemChange.emit(selectedItem);
  }

  clearItems(): void {
    if (!Array.isArray(this.selectedItem)) {
      this.items = [];
    }
  }

  onRemoveItem(selectedItem: AutoCompleteItem): void {
    if (Array.isArray(this.selectedItem)) {
      this.selectedItem = this.selectedItem?.filter(item => item.value !== selectedItem.value);
      this.removeItemChange.emit(this.selectedItem);
    }
  }

  /**
   * Filters dublicate items if we use multiple option, otherwise we just update this.items
   * Note: if this.selectedItem is array actually this means that multiple option is turned on.
   */
  private filterItemsByMultiple(items: AutoCompleteItem[]): void {
    if (!Array.isArray(this.selectedItem)) {
      this.items = [...items];
      return;
    }

    const selectedItems: AutoCompleteItem[] = [...this.selectedItem];

    this.items = [...items].filter(newItem => {
      return selectedItems.find(selectedItem => selectedItem.value === newItem.value) === undefined;
    });
  }
}
