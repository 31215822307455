import { HttpParams } from '@angular/common/http';
import { Component, isDevMode, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppRoute } from 'src/app/constants/app.route';
import { AuthUserService } from 'src/app/core/services/auth/auth-user.service';
import { AuthProvider } from 'src/app/core/services/http/general/enums/auth-provider';
import { environment } from 'src/environments/environment';
import { AuthTokenRequest } from '../../services/http/auth/interface/auth-token-request';

@Component({
  template: ''
})
export class AuthCallBackComponent implements OnInit {
  constructor(
    protected activatedRoute: ActivatedRoute,
    protected authUserService: AuthUserService,
    protected router: Router
  ) {}

  async ngOnInit(): Promise<void> {
    const authTokenRequest = await this.getAuthTokenRequest();

    if (authTokenRequest) {
      await this.authUserService.setUserData(authTokenRequest);
      this.authUserService.removeVerifierCode();
      this.router.navigateByUrl(AppRoute.Login);
    }
  }

  protected async getAuthTokenRequest(): Promise<AuthTokenRequest | undefined> {
    const authProviderId = this.getAuthProviderId();
    const verifierCode = await this.getVerifierCode();
    const code = this.activatedRoute.snapshot.queryParams.code;
    const error = this.activatedRoute.snapshot.queryParams.error;
    if (error === 'access_denied') {
      this.router.navigateByUrl(AppRoute.Login);
    }
    if (!authProviderId || !(authProviderId in AuthProvider) || code.length === 0) {
      if (isDevMode()) {
        console.error('authProvider and authCode');
      }

      return;
    }

    return {
      code,
      authProviderId,
      codeVerifier: verifierCode,
      redirectUri: this.redirectUri
    };
  }

  protected getAuthProviderId(): number | void {
    const stateParams = new HttpParams({
      fromString: decodeURIComponent(this.activatedRoute.snapshot.queryParams.state)
    });

    if (stateParams.keys().length === 0) {
      if (isDevMode()) {
        console.error('state must contain x-www-form-encoded data');
      }

      return;
    }

    return Number(stateParams.get('providerId'));
  }

  protected async getVerifierCode() {
    return this.authUserService.getVerifierCode();
  }

  protected get redirectUri() {
    return `${environment.domainUrl}/${this.activatedRoute.snapshot.routeConfig?.path}`;
  }
}
