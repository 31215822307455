import { Component, OnInit } from '@angular/core';
import { EventManagerService } from '../../core/services/event-manager/event-manager.service';
import { EventType } from '../../constants/events-type';
import { EventContent } from '../../core/services/event-manager/models/event-content';
import { AppConfig } from '../../configs/app.config';
import { Notification } from '../../core/services/http/notification/interfaces/notification';
import { delay } from 'rxjs/operators';
import { of } from 'rxjs';
import { Authority } from '../../constants/authority';
import { AuthFactoryService } from '../../core/services/auth/auth-factory.service';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-notifications-broadcast',
  templateUrl: './notifications-broadcast.component.html'
})
export class NotificationsBroadcastComponent implements OnInit {
  appConfig = AppConfig;
  public notifications: Array<Notification> = [];
  public authority: Authority | undefined;

  constructor(
      private eventManager: EventManagerService,
      private authFactoryService: AuthFactoryService,
      private router: Router
  ) {}

  ngOnInit(): void {
    this.authority = this.authFactoryService.service.getSchoolUserAuthority()?.role;
    if (!environment.mobile) {
      this.eventManager.subscribe(EventType.Notification, (event: EventContent<Notification>) => {
        const notification: Notification = event.content;
        this.notifications.push(notification);
        of(null).pipe(delay(5 * 1000)).subscribe(() => this.dismiss(notification));
      });
    }
  }

  dismiss(notification: Notification): void {
    this.notifications.splice(
        this.notifications.findIndex((cNotification: Notification) => cNotification.uuid === notification.uuid),
        1
    );
  }

  public goToNotifications(notification: Notification): void {
    this.dismiss(notification);
    this.router.navigate(['/notifications']);
  }

  protected readonly Authority = Authority;
}
