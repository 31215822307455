import { CanActivate, Router } from '@angular/router';

import { AppConfig } from 'src/app/configs/app.config';
import { AppRoute } from 'src/app/constants/app.route';
import { AuthFactoryService } from '../services/auth/auth-factory.service';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class DefaultRouteGuard implements CanActivate {
  constructor(private router: Router, private authFactoryService: AuthFactoryService) {}

  canActivate(): boolean {
    const schoolUserAuthority = this.authFactoryService.service.getSchoolUserAuthority();
    let defaultRoute = AppRoute.Login;

    if (schoolUserAuthority) {
      const route = AppConfig.DefaultRouteUserRoleMapping[schoolUserAuthority.role];

      if (route) {
        defaultRoute = route;
      }
    }

    this.router.navigateByUrl(defaultRoute);

    return false;
  }
}
