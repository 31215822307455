import { Injectable } from '@angular/core';
import { SchoolYear } from './school-year.interface';
import { ExtendedDate } from '../classes/extended-date';
import { AppConfig } from '../../../../configs/app.config';
import { AuthFactoryService } from '../../auth/auth-factory.service';
import { LocalStorageService } from '../../local-storage/local-storage.service';

@Injectable({
    providedIn: 'root'
})
export class SchoolYearRegistry {
    private availableSchoolYears: Array<SchoolYear> = [];
    constructor(
        private authFactoryService: AuthFactoryService,
        private localStorageService: LocalStorageService
    ) {}

    public get availableYears(): Array<SchoolYear> {
        if (this.availableSchoolYears.length === 0) {
            const school = this.authFactoryService.service.getSchoolUserAuthority();

            if (school && school.schoolYears) {
                this.availableSchoolYears =  school.schoolYears.map(this.mapSchoolYearDates);
            }
        }

        return this.availableSchoolYears;
    }

    public set selectedSchoolYear(year: SchoolYear) {
        this.localStorageService.setObject('schoolYearSettings', year);
    }

    public get selectedSchoolYear(): SchoolYear {
        if (this.localStorageService.getObject('schoolYearSettings')) {
            return this.mapSchoolYearDates(this.localStorageService.getObject('schoolYearSettings') as SchoolYear);
        } else if (this.availableYears.length > 0) {
            this.selectedSchoolYear = this.getCurrentSchoolYear();
        }

        return this.getCurrentSchoolYear();
    }

    public getCurrentSchoolYear(): SchoolYear {
        const currentSchoolYear = this.availableYears.find(
            (schoolYear: SchoolYear) => {
                return schoolYear.startDate <= new ExtendedDate() && schoolYear.endDate >= new ExtendedDate();
            }
        );

        if (currentSchoolYear) {
            return currentSchoolYear;
        }

        return {
            schoolYear: AppConfig.SchoolYear.StartYear,
            startDate: new ExtendedDate(AppConfig.SchoolYear.StartYear, 6, 1),
            endDate: new ExtendedDate(AppConfig.SchoolYear.StartYear + 1, 5, 30),
        };
    }

    private mapSchoolYearDates(schoolYear: SchoolYear): SchoolYear
    {
        schoolYear.startDate = new ExtendedDate(schoolYear.startDate);
        schoolYear.endDate = new ExtendedDate(schoolYear.endDate);

        return schoolYear;
    }
}
