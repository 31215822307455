<div class="blank-header">
  <img src="assets/images/flex-logo-color.svg" width="126" alt="Eduspire Solutions Logo" />
</div>

<div class="blank-page">
  <div class="p-4 text-center">
    <h1 class="mb-0">{{ 'ACCESS_DENIED' | translate }}</h1>
    <p>{{ 'ACCESS_DENIED_DESCRIPTION' | translate }}</p>
    <button type="button" class="btn btn-primary btn-md-padded btn-radius" routerLink="/login">
      {{ 'LOGIN' | translate }}
    </button>
  </div>
</div>
