import { AuthFactoryService } from '../services/auth/auth-factory.service';

export function timeZoneFactory(authFactoryService: AuthFactoryService): string | undefined {
  const schoolUtcOffsetInSec = authFactoryService.service.getSchoolUtcOffsetInSec();

  if (schoolUtcOffsetInSec === undefined) {
    return;
  }

  const timeZone = schoolUtcOffsetInSec / 3600;
  return (timeZone > 0 ? 'UTC+' : 'UTC') + timeZone;
}
