import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AppRoute } from 'src/app/constants/app.route';
import { LocalStorageService } from '../services/local-storage/local-storage.service';

@Injectable({ providedIn: 'root' })
export class CacheInterceptor implements HttpInterceptor {
  private cachePeriodMiliSec = 5 * 1000;
  private cacheListEndpointMap: {
    // nonCachedRoute - Route which cache is disabled
    nonCachedRoute: AppRoute;
    endpointUrl: string;
    storageKey: 'activityPriority' | 'systemSettings' | 'authenticatedUser';
  }[] = [
    { nonCachedRoute: AppRoute.Priorities, endpointUrl: 'activity-priority', storageKey: 'activityPriority' },
    { nonCachedRoute: AppRoute.MyProfile, endpointUrl: 'authenticated-user', storageKey: 'authenticatedUser' },
    { nonCachedRoute: AppRoute.SystemSettings, endpointUrl: 'settings', storageKey: 'systemSettings' }
  ];

  constructor(private localStorageService: LocalStorageService, private router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const cachedResponse = this.getCachedResponse(request);

    if (cachedResponse) {
      return cachedResponse;
    }

    return this.setCachedResponse(request, next);
  }

  private setCachedResponse(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          const target = this.cacheListEndpointMap.find(item => request.url.endsWith(item.endpointUrl));

          if (target) {
            this.localStorageService.setItem(target.storageKey, this.serializeResponse(event));
            this.setCachedPeriod();
            return of(event);
          }
        }

        return next.handle(request);
      })
    );
  }

  private getCachedResponse(request: HttpRequest<any>): Observable<HttpResponse<any>> | undefined {
    const targetCachedEndpoint = this.cacheListEndpointMap.find(
      item => request.url.endsWith(item.endpointUrl) && item.nonCachedRoute !== this.getCurrentRouter()
    );

    if (!targetCachedEndpoint) {
      return;
    }

    const cachedPeriod = this.getCachedPeriod();

    if (!cachedPeriod) {
      return;
    }

    if (!this.isCachePeriodExpired(cachedPeriod)) {
      const resultString = this.localStorageService.getItem(targetCachedEndpoint.storageKey);

      if (!resultString) {
        return;
      }

      if (resultString) {
        return of(this.deserializeResponse(resultString));
      }
    }

    return;
  }

  private isCachePeriodExpired(cachePeriod: number): boolean {
    return cachePeriod < Date.now();
  }

  private getCachedPeriod(): number | undefined {
    const cachedPeriod = Number(this.localStorageService.getItem('cachedPeriod'));

    if (cachedPeriod > 0) {
      return cachedPeriod;
    }

    return;
  }

  private setCachedPeriod(): void {
    this.localStorageService.setItem('cachedPeriod', Date.now() + this.cachePeriodMiliSec);
  }

  private serializeResponse(res: HttpResponse<any>): string {
    const response = res.clone();
    return JSON.stringify({
      headers: this.getHeaders(res),
      status: response.status,
      statusText: response.statusText,
      url: response.url,
      body: response.body
    });
  }

  private deserializeResponse<T = any>(res: string): HttpResponse<T> {
    const response = JSON.parse(res);
    const headers = new HttpHeaders(response.headers);
    return new HttpResponse({
      headers,
      body: response.body,
      status: response.status,
      statusText: response.statusText,
      url: response.url
    });
  }

  private getHeaders(response: HttpResponse<any>): Record<string, string> {
    const headers: Record<string, string> = {};

    response.headers.keys().forEach(key => {
      const value = response.headers.get(key);

      if (value) {
        headers[key] = value;
      }
    });

    return headers;
  }

  private getCurrentRouter(): AppRoute {
    return this.router.url.split('?')[0].replace('/', '') as AppRoute;
  }
}
