<div *ngIf="shouldShowSimulateBar" class="simulate-bar d-flex justify-content-between align-items-center">
  <i class="icon-simulate ftmi-impersonate text-primary" [appTooltip]="'SIMULATE' | translate" placement="right"></i>

  <div class="app-input-inline simulate-input">
    <app-auto-complete
      (selectedItemChange)="onSelectChangeUser($event)"
      (searchChange)="onSearchChangeUser($event)"
      [itemsData]="users"
      [selectedItem]="form.controls.user.value"
    ></app-auto-complete>
  </div>

  <button type="button" (click)="onReturn()" class="btn btn-text">{{ 'RETURN' | translate }}</button>
</div>
