import { NgModule } from '@angular/core';
import { ActivityRosterStudentsComponent } from 'src/app/features/activity/shared/activity-settings/activity-roster-students/activity-roster-students.component';
import { SharedModule } from '../shared.module';
import { AutoCompleteListBySelectionComponent } from './auto-complete-list-by-selection/auto-complete-list-by-selection.component';
import { AutoCompleteListGroupComponent } from './auto-complete-list-group/auto-complete-list-group.component';
import { AutoCompleteComponent } from './auto-complete.component';
import { AutoCompleteAssignTeacherComponent } from './extensions/auto-complete-assign-teacher/auto-complete-assign-teacher.component';
import { AutoCompleteListGroupStudentComponent } from './extensions/auto-complete-list-group-student/auto-complete-list-group-student.component';
import { AutoCompleteListGroupTeacherComponent } from './extensions/auto-complete-list-group-teacher/auto-complete-list-group-teacher.component';
import { AutoCompleteRoomComponent } from './extensions/auto-complete-room/auto-complete-room.component';
import { AutoCompleteUserComponent } from './extensions/auto-complete-user/auto-complete-user.component';
import { AutoCompleteListGroupRosterComponent } from './extensions/auto-complete-list-group-roster/auto-complete-list-group-roster.component';

@NgModule({
  declarations: [
    AutoCompleteComponent,
    AutoCompleteListGroupComponent,
    AutoCompleteListBySelectionComponent,
    ActivityRosterStudentsComponent,
    AutoCompleteAssignTeacherComponent,
    AutoCompleteListGroupTeacherComponent,
    AutoCompleteRoomComponent,
    AutoCompleteListGroupStudentComponent,
    AutoCompleteUserComponent,
    AutoCompleteListGroupRosterComponent
  ],
  imports: [SharedModule],
  exports: [
    AutoCompleteComponent,
    AutoCompleteListGroupComponent,
    AutoCompleteListBySelectionComponent,
    ActivityRosterStudentsComponent,
    AutoCompleteAssignTeacherComponent,
    AutoCompleteListGroupTeacherComponent,
    AutoCompleteRoomComponent,
    AutoCompleteListGroupStudentComponent,
    AutoCompleteUserComponent,
    AutoCompleteListGroupRosterComponent
  ]
})
export class AutoCompleteModule {}
