<div [class.notification-horizontal]="authority === Authority.Student || authority === Authority.Substitute">
<div class="notifications-broadcast notifications-position">
    <div class="notification-card notification-radius" *ngFor="let notification of notifications" (click)="goToNotifications(notification)">
      <div class="notification-icon" [appTooltip]="'NOTIFICATIONS' | translate"><i class="ftmi-notification"></i></div>
      <div class="notification-body">
        <div>
          <span class="notification-title">{{notification.heading}}</span>
          <span class="notification-time">{{notification.created | extendedDate: appConfig.Formats.TimeFormatMidDay}}</span>
        </div>
        <div class="notification-message" [innerHTML]="notification.content | sanitizeHtml">
        </div>
        <div class="notification-link">
          <app-notification-link [notification]="notification"></app-notification-link>
        </div>
      </div>
      <div class="close">
        <button type="button" class="round-btn round-btn-xs" (click)="dismiss(notification)">
          <i class="ftmi-cross"></i>
        </button>
      </div>
    </div>
  </div>
</div>
