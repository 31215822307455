import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class TokenStorageService {
    private readonly TOKEN_KEY_NAME: string = '__gga_page_counter';
    private readonly CRYPTO_KEY: string = 'wknN4l8mr0KUmjY6WZYwi2FTv0eg8fIy';

    constructor(
        private localStorageService: LocalStorageService
    ) {
    }

    public get(): string
    {
        const rawToken: string = this.localStorageService.getItem(this.tokenKeyName);

        if (!rawToken) {
            return '';
        }

        if (!environment.mobile) {
            try {
                return CryptoJS.AES.decrypt(rawToken, this.cryptoKey).toString(CryptoJS.enc.Utf8);
            } catch (e) {
                return '';
            }
        } else {
            return rawToken;
        }
    }

    public set(token: string): void
    {
        let data: any = token;

        if (!environment.mobile) {
            data = CryptoJS.AES.encrypt(token, this.cryptoKey);
        }

        this.localStorageService.setItem(
            this.tokenKeyName,
            data
        );
    }

    private get tokenKeyName(): string {
        return (environment.mobile) ? 'token' : this.TOKEN_KEY_NAME;
    }

    private get cryptoKey(): string {
        return !window.name ? this.CRYPTO_KEY : window.name;
    }
}
