import { AutoCompleteModule } from '../shared/auto-complete/autocomplete.module';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { SimulateBarComponent } from './simulate-bar/simulate-bar.component';

@NgModule({
  declarations: [SimulateBarComponent],
  imports: [SharedModule, AutoCompleteModule],
  exports: [SimulateBarComponent]
})
export class SimulateModule {}
