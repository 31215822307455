export class ExtendedDate extends Date {
  isDateUtc: boolean | undefined;
  isCalculatedToSpecificTimeZone: boolean | undefined;

  constructor(date?: ExtendedDate | Date | string | number | undefined);
  constructor(
    year: number,
    month: number,
    day?: number,
    hours?: number,
    minutes?: number,
    seconds?: number,
    milliseconds?: number
  );
  // @todo make tuple have a type
  constructor(...args: any[]) {
    if (args.length > 0) {
      const targetString = args.find((item: any) => typeof item === 'string') as string;

      if (targetString) {
        super(removeTimeZone(targetString));
      } else {
        // @ts-ignore
        super(...args);
      }

      this.initializeExtendedDateWithArgs(args);
    } else {
      super();
      this.initializeExtendedDate();
    }

    this.isDateUtc = true;
  }

  getDateAsString(): string {
    const d = this.getDate();
    const m = this.getMonth() + 1;
    const y = this.getFullYear();
    return '' + y + '-' + (m <= 9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
  }

  resetHours(): void {
    this.setHours(0, 0, 0, 0);
  }

  private initializeExtendedDateWithArgs(args: any[]): void {
    const targetString = args.find((item: any) => typeof item === 'string' && item.length === 10);
    const targetExtendedDate = args.find((item: any) => item instanceof ExtendedDate);

    if (targetString) {
      this.setDateFromString(targetString);
    } else if (targetExtendedDate) {
      if (!targetExtendedDate.isDateUtc) {
        this.setDateTimeToUtc();
      }
    }
  }

  private initializeExtendedDate(): void {
    this.setDateTimeToUtc();
  }

  private setDateFromString(targetString: any): void {
    if (!targetString.includes('-')) {
      throw new Error(`'date string should be in such format 2021-01-10' ${targetString} given`);
    } else {
      const targetArgs: [number, number, number] = targetString.split('-');
      this.setDateBy(...targetArgs);
    }
  }

  private setDateTimeToUtc(): void {
    const selectedDateUtc = new Date(this.getTime() + this.getTimezoneOffset() * 60000);

    this.setTime(
      new Date(
        selectedDateUtc.getFullYear(),
        selectedDateUtc.getMonth(),
        selectedDateUtc.getDate(),
        selectedDateUtc.getHours(),
        selectedDateUtc.getMinutes(),
        selectedDateUtc.getSeconds(),
        0
      ).getTime()
    );
  }

  private setDateBy(year: number, month: number, date: number): void {
    this.setTime(new Date(year, month - 1, date, 0, 0, 0, 0).getTime());
  }
}

function removeTimeZone(targetString: string): string {
  let indexEnd = targetString.length;
  const targetIndex = targetString.indexOf('GMT');

  if (targetIndex !== -1) {
    indexEnd = targetIndex;
  }

  return targetString.substring(0, indexEnd);
}
