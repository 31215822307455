import { Injectable, isDevMode } from '@angular/core';
import { CanLoad, Route, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AppRoute } from 'src/app/constants/app.route';
import { Authority } from 'src/app/constants/authority';
import { AuthFactoryService } from '../services/auth/auth-factory.service';

@Injectable({ providedIn: 'root' })
export class RouteGuard implements CanLoad {
  constructor(private authFactoryService: AuthFactoryService, private router: Router) {}

  canLoad(route: Route): Observable<boolean> {
    return new Observable(observer => {
      const checkLogin = this.checkLogin(route?.data?.authority as Authority[]);

      if (checkLogin) {
        observer.next(true);
      } else {
        observer.next(false);
      }

      observer.complete();
    });
  }

  private checkLogin(authority: Authority[]): boolean {
    const hasAnyAuthority = this.authFactoryService.service.hasAnyAuthority(authority);

    if (hasAnyAuthority) {
      return true;
    } else {
      if (isDevMode()) {
        console.error('User has not any of required authority: ', authority);
      }

      this.router.navigateByUrl(AppRoute.NoAccess);
    }

    return false;
  }
}
