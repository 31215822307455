import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RestGenericUtilService {
  toKeyValueArray(name: string, values: any[]): Record<any, any>[] | undefined {
    return values.map(item => {
      return { [`${name}[]`]: item };
    });
  }

  keyValueArrayToQueryParams(array?: Record<string, any>[]): string {
    let params = '';

    array?.forEach((element, index: number) => {
      for (const key in element) {
        if (Object.prototype.hasOwnProperty.call(element, key)) {
          const value = element[key];
          params += key + '=' + value;

          if (index < array.length - 1) {
            params += '&';
          }
        }
      }
    });

    return params;
  }

  toParamsFromArray(requestType?: unknown[], parameterName = 'uuids'): HttpParams {
    let params = new HttpParams();

    if (!requestType) {
      return params;
    }

    params = params.append(parameterName, requestType.toString());

    return params;
  }

  toParams(requestType?: any, excludedParamsKeys?: string[]): HttpParams {
    let params = new HttpParams();

    if (!requestType) {
      return params;
    }

    for (const key in requestType) {
      if (Object.prototype.hasOwnProperty.call(requestType, key)) {
        if (excludedParamsKeys?.includes(key)) {
          continue;
        }

        const value = requestType[key];
        params = params.append(key, String(value));
      }
    }

    return params;
  }

  mergeParams(paging: any, filter: any) {
    const result = { ...paging };
    
    for (const key in filter) {
      if (Object.prototype.hasOwnProperty.call(filter, key) && filter[key] != null) {
        result[key] = filter[key];
      }
    }
    
    return result;
  }
}
