import { Observable, Observer, Subscription } from 'rxjs';
import { filter, map, share } from 'rxjs/operators';

import { EventContent } from './models/event-content';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EventManagerService {
  observable: Observable<EventContent<any> | string>;
  observer: Observer<EventContent<any> | string> | undefined;

  constructor() {
    this.observable = new Observable(
      (observer: Observer<EventContent<any> | string>) => {
        this.observer = observer;
      }
    ).pipe(share());
  }

  /**
   * Method to broadcast the event to observer
   */
  broadcast(event: EventContent<any> | string): void {
    if (this.observer) {
      this.observer.next(event);
    }
  }

  /**
   * Method to subscribe to an event with callback
   */
  subscribe(eventName: string, callback: any): Subscription {
    const subscriber: Subscription = this.observable
      .pipe(
        filter((event: EventContent<any> | string) => {
          if (typeof event === 'string') {
            return event === eventName;
          }
          return event.name === eventName;
        }),
        map((event: EventContent<any> | string) => {
          if (typeof event !== 'string') {
            return event;
          } else {
            return eventName;
          }
        })
      )
      .subscribe(callback);
    return subscriber;
  }

  /**
   * Method to unsubscribe the subscription
   */
  destroy(subscriber: Subscription): void {
    subscriber.unsubscribe();
  }
}
