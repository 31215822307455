export enum NotificationType {
    STUDENT_SCHEDULE_CHANGE_PRIORITY_ID = 1,
    TEACHER_UNSCHEDULED_ACTIVITY_ID = 2,
    STUDENT_REMOVED_FROM_ACTIVITY_ID = 3,
    SCHEDULED_ACTIVITY_MOVED_ID = 4,
    SCHEDULED_ACTIVITY_ROOM_CHANGED_ID = 5,
    SCHEDULED_ACTIVITY_TITLE_OR_DESCRIPTION_CHANGED_ID = 6,
    LUNCH_REGISTRATION_CREATED_ID = 7,
    LUNCH_REGISTRATION_REMOVED_ID = 8,
    ROLLBACK_TO_CATCHALL_ID = 9,
    FLEX_PERIOD_CANCELLATION_CREATED_ID = 10,
    FLEX_PERIOD_CANCELLATION_UPDATED_ID = 11,
    FLEX_PERIOD_CANCELLATION_REMOVED_ID = 12,
    ATTENDANCE_REMINDER_ID = 13,
    ATTENDANCE_REPORT_ID = 14,
    STUDENT_RESTRICTION_CREATED_ID = 15,
    STUDENT_RESTRICTION_REMOVED_ID = 16,
    STUDENT_RESTRICTION_NOTE_CREATED_BY_ADULT = 17,
    STUDENT_RESTRICTION_NOTE_CREATED_BY_STUDENT = 18,
    ROLLBACK_TO_ACTIVITY = 19,
    TEACHER_RESTRICTION_NOTE_CREATED = 20,
    STUDENT_AGENDA_ID = 21,
}
