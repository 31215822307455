import { Injectable } from '@angular/core';
import { CanLoad } from '@angular/router';
import { AuthFactoryService } from '../services/auth/auth-factory.service';

@Injectable({ providedIn: 'root' })
export class NotAuthGuard implements CanLoad {
  constructor(private authFactoryService: AuthFactoryService) {}

  canLoad(): boolean {
    return this.authFactoryService.service.isTokenExpired();
  }
}
