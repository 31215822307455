import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AppRoute } from './constants/app.route';
import { Authority } from './constants/authority';
import { AuthCallBackMobileComponent } from './core/component/auth-call-back-mobile/auth-call-back-mobile.component';
import { AuthCallBackComponent } from './core/component/auth-call-back/auth-call-back.component';
import { ProviderAuthCallBackComponent } from './core/component/provider-auth-callback/provider-auth-call-back.component';
import { AuthGuard } from './core/guards/auth.guard';
import { DefaultRouteGuard } from './core/guards/default-route.guard';
import { NotAuthGuard } from './core/guards/not-auth.guard';
import { RouteGuard } from './core/guards/route.guard';
import { AuthProvider } from './core/services/http/general/enums/auth-provider';
import { NoAccessComponent } from './no-access/no-access.component';
import { NotFoundComponent } from './not-found/not-found.component';

const routes: Routes = [
  { path: '', redirectTo: '', pathMatch: 'full', canActivate: [DefaultRouteGuard] },
  { path: 'clever', component: ProviderAuthCallBackComponent, data: { provider: AuthProvider.Clever } },
  { path: 'classlink', component: ProviderAuthCallBackComponent, data: { provider: AuthProvider.ClassLink } },
  { path: 'gg4l', component: ProviderAuthCallBackComponent, data: { provider: AuthProvider.GG4L } },
  { path: 'edlink', component: ProviderAuthCallBackComponent, data: { provider: AuthProvider.Edlink } },
  {
    path: AppRoute.Roster,
    data: {
      authority: [Authority.Admin, Authority.Staff, Authority.Teacher]
    },
    canLoad: [AuthGuard, RouteGuard],
    loadChildren: () => import('./features/roster/roster.module').then(m => m.RosterModule)
  },
  {
    path: AppRoute.Activity,
    data: {
      authority: [Authority.Admin, Authority.Staff, Authority.Teacher]
    },
    canLoad: [AuthGuard, RouteGuard],
    loadChildren: () => import('./features/activity/activity.module').then(m => m.ActivityModule)
  },
  {
    path: AppRoute.Flex,
    data: {
      authority: [Authority.Admin]
    },
    canLoad: [AuthGuard, RouteGuard],
    loadChildren: () => import('./features/flex/flex.module').then(m => m.FlexModule)
  },
  {
    path: AppRoute.Schedule,
    data: {
      authority: [Authority.Teacher, Authority.Staff, Authority.Student, Authority.Admin]
    },
    canLoad: [AuthGuard, RouteGuard],
    loadChildren: () => import('./features/schedule/schedule.module').then(m => m.ScheduleModule)
  },
  {
    path: AppRoute.Directory,
    data: {
      authority: [Authority.Student, Authority.Teacher, Authority.Staff, Authority.Admin]
    },
    canLoad: [AuthGuard, RouteGuard],
    loadChildren: () => import('./features/directory/directory.module').then(m => m.DirectoryModule)
  },
  {
    path: AppRoute.Login,
    loadChildren: () => import('./features/login/login.module').then(m => m.LoginModule)
  },
  {
    path: AppRoute.GoogleLogin,
    loadChildren: () => import('./features/login/login.module').then(m => m.LoginModule)
  },
  {
    path: AppRoute.ClasslinkLogin,
    loadChildren: () => import('./features/login/login.module').then(m => m.LoginModule)
  },
  {
    path: AppRoute.EdlinkLogin,
    loadChildren: () => import('./features/login/login.module').then(m => m.LoginModule)
  },
  {
    path: AppRoute.CleverLogin,
    loadChildren: () => import('./features/login/login.module').then(m => m.LoginModule)
  },
  {
    path: AppRoute.MicrosoftLogin,
    loadChildren: () => import('./features/login/login.module').then(m => m.LoginModule)
  },
  { path: AppRoute.AuthCallBackMobile, component: AuthCallBackMobileComponent },

  {
    path: AppRoute.VerifyEmailAddress,
    loadChildren: () =>
      import('./features/email-verification-code/email-verification-code.module').then(
        m => m.EmailVerificationCodeModule
      )
  },
  { path: AppRoute.AuthCallBack, component: AuthCallBackComponent },
  { path: AppRoute.AuthCallBackMobile, component: AuthCallBackMobileComponent },
  {
    path: AppRoute.Dashboard,
    data: {
      authority: [Authority.Admin]
    },
    canLoad: [AuthGuard, RouteGuard],
    loadChildren: () => import('./features/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: AppRoute.Cancellation,
    data: {
      authority: [Authority.Admin, Authority.Teacher, Authority.Staff]
    },
    canLoad: [AuthGuard, RouteGuard],
    loadChildren: () => import('./features/cancellation/cancellation.module').then(m => m.CancellationModule)
  },
  {
    path: AppRoute.CutList,
    data: {
      authority: [Authority.Admin, Authority.Staff]
    },
    canLoad: [AuthGuard, RouteGuard],
    loadChildren: () => import('./features/cut-list/cut-list.module').then(m => m.CutListModule)
  },
  {
    path: AppRoute.ActivityRegistrationsExport,
    data: {
      authority: [Authority.Admin, Authority.Staff, Authority.Teacher]
    },
    canLoad: [AuthGuard, RouteGuard],
    loadChildren: () =>
      import('./features/activity-registration-export/activity-registration-export.module').then(
        m => m.ActivityRegistrationExportModule
      )
  },
  {
    path: AppRoute.FullAttendance,
    data: {
      authority: [Authority.Admin, Authority.Staff]
    },
    canLoad: [AuthGuard, RouteGuard],
    loadChildren: () => import('./features/full-attendance/full-attendance.module').then(m => m.FullAttendanceModule)
  },
  {
    path: AppRoute.ExcludedStudents,
    data: {
      authority: [Authority.Admin, Authority.Staff]
    },
    canLoad: [AuthGuard, RouteGuard],
    loadChildren: () =>
      import('./features/excluded-students/excluded-students.module').then(m => m.ExcludedStudentsModule)
  },
  {
    path: AppRoute.AttendanceActivity,
    data: {
      authority: [Authority.Admin, Authority.Staff]
    },
    canLoad: [AuthGuard, RouteGuard],
    loadChildren: () =>
      import('./features/attendance-activity/attendance-activity.module').then(m => m.AttendanceActivityModule)
  },
  {
    path: AppRoute.RoomUse,
    data: {
      authority: [Authority.Admin, Authority.Staff]
    },
    canLoad: [AuthGuard, RouteGuard],
    loadChildren: () => import('./features/room-use/room-use.module').then(m => m.RoomUseModule)
  },
  {
    path: AppRoute.StudentAttendance,
    data: {
      authority: [Authority.Admin, Authority.Staff, Authority.Teacher, Authority.Substitute]
    },
    canLoad: [AuthGuard, RouteGuard],
    loadChildren: () =>
      import('./features/student-attendance/student-attendance.module').then(m => m.StudentAttendanceModule)
  },
  {
    path: AppRoute.StudentSearch,
    data: {
      authority: [Authority.Admin, Authority.Staff, Authority.Teacher]
    },
    canLoad: [AuthGuard, RouteGuard],
    loadChildren: () => import('./features/student-search/student-search.module').then(m => m.StudentSearchModule)
  },
  {
    path: AppRoute.StudentList,
    data: {
      authority: [Authority.Admin, Authority.Staff, Authority.Teacher]
    },
    canLoad: [AuthGuard, RouteGuard],
    loadChildren: () => import('./features/student-list/student-list.module').then(m => m.StudentListModule)
  },
  {
    path: AppRoute.RestrictedStudents,
    data: {
      authority: [Authority.Admin, Authority.Staff, Authority.Teacher, Authority.Student]
    },
    canLoad: [AuthGuard, RouteGuard],
    loadChildren: () =>
      import('./features/restricted-students/restricted-students.module').then(m => m.RestrictedStudentsModule)
  },
  {
    path: AppRoute.ABPolarity,
    data: {
      authority: [Authority.Admin]
    },
    canLoad: [AuthGuard, RouteGuard],
    loadChildren: () => import('./features/ab-polarity/ab-polarity.module').then(m => m.ABPolarityModule)
  },
  {
    path: AppRoute.TeacherList,
    data: {
      authority: [Authority.Admin, Authority.Staff]
    },
    canLoad: [AuthGuard, RouteGuard],
    loadChildren: () => import('./features/teacher-list/teacher-list.module').then(m => m.TeacherListModule)
  },
  {
    path: AppRoute.TeacherAttendance,
    data: {
      authority: [Authority.Admin, Authority.Staff]
    },
    canLoad: [AuthGuard, RouteGuard],
    loadChildren: () =>
      import('./features/teacher-attendance/teacher-attendance.module').then(m => m.TeacherAttendanceModule)
  },
  {
    path: AppRoute.Substitute,
    data: {
      authority: [Authority.Admin, Authority.Staff]
    },
    canLoad: [AuthGuard, RouteGuard],
    loadChildren: () => import('./features/substitute/substitute.module').then(m => m.SubstituteModule)
  },
  {
    path: AppRoute.ActivityTypes,
    data: {
      authority: [Authority.Admin]
    },
    canLoad: [AuthGuard, RouteGuard],
    loadChildren: () => import('./features/activity-types/activity-types.module').then(m => m.ActivityTypesModule)
  },
  {
    path: AppRoute.Priorities,
    data: {
      authority: [Authority.Admin]
    },
    canLoad: [AuthGuard, RouteGuard],
    loadChildren: () => import('./features/priorities/priorities.module').then(m => m.PrioritiesModule)
  },
  {
    path: AppRoute.Room,
    data: {
      authority: [Authority.Admin]
    },
    canLoad: [AuthGuard, RouteGuard],
    loadChildren: () => import('./features/room/room.module').then(m => m.RoomModule)
  },
  {
    path: AppRoute.User,
    data: {
      authority: [Authority.Admin]
    },
    canLoad: [AuthGuard, RouteGuard],
    loadChildren: () => import('./features/user/user.module').then(m => m.UserModule)
  },
  {
    path: AppRoute.Announcement,
    data: {
      authority: [Authority.Admin]
    },
    canLoad: [AuthGuard, RouteGuard],
    loadChildren: () => import('./features/announcement/announcement.module').then(m => m.AnnouncementModule)
  },
  {
    path: AppRoute.Log,
    data: {
      authority: [Authority.Admin]
    },
    canLoad: [AuthGuard, RouteGuard],
    loadChildren: () => import('./features/log/log.module').then(m => m.LogModule)
  },
  {
    path: AppRoute.SystemSettings,
    data: {
      authority: [Authority.Admin]
    },
    canLoad: [AuthGuard, RouteGuard],
    loadChildren: () => import('./features/system-settings/system-settings.module').then(m => m.SystemSettingsModule)
  },
  {
    path: AppRoute.SftpFiles,
    data: {
      authority: [Authority.Admin]
    },
    canLoad: [AuthGuard, RouteGuard],
    loadChildren: () => import('./features/sftp-files/sftp-files.module').then(m => m.SftpFilesModule)
  },
  {
    path: AppRoute.ResetPasswordInitiate,
    canLoad: [NotAuthGuard],
    loadChildren: () =>
      import('./features/reset-password-initiate/reset-password-initiate.module').then(
        m => m.ResetPasswordInitiateModule
      )
  },
  {
    path: AppRoute.ResetPassword,
    canLoad: [NotAuthGuard],
    loadChildren: () => import('./features/reset-password/reset-password.module').then(m => m.ResetPasswordModule)
  },
  {
    path: AppRoute.NoAccess,
    component: NoAccessComponent
  },
  {
    path: AppRoute.TrainingVideos,
    canLoad: [AuthGuard, RouteGuard],
    loadChildren: () => import('./features/training-videos/training-videos.module').then(m => m.TrainingVideosModule)
  },
  {
    path: AppRoute.Documentation,
    canLoad: [AuthGuard, RouteGuard],
    loadChildren: () => import('./features/documentation/documentation.module').then(m => m.DocumentationModule)
  },

  {
    path: AppRoute.AuthExternalCallBack,
    loadChildren: () =>
      import('./features/auth-external-call-back/auth-external-call-back.module').then(
        m => m.AuthExternalCallBackModule
      )
  },
  {
    path: AppRoute.MyProfile,
    loadChildren: () => import('./features/my-profile/my-profile.module').then(m => m.MyProfileModule)
  },
  {
    path: `${AppRoute.SystemSettings}/${AppRoute.AttendanceReminder}`,
    data: {
      authority: [Authority.Admin]
    },
    canLoad: [AuthGuard, RouteGuard],
    loadChildren: () =>
      import('./features/system-settings-attendance-reminder/system-settings-attendance-reminder.module').then(
        m => m.SystemSettingsAttendanceReminderModule
      )
  },
  {
    path: `${AppRoute.SystemSettings}/${AppRoute.AutoSyncGroups}`,
    data: {
      authority: [Authority.Admin]
    },
    canLoad: [AuthGuard, RouteGuard],
    loadChildren: () =>
        import('./features/system-settings-auto-sync-groups/system-settings-auto-sync-groups.module').then(
            m => m.SystemSettingsAutoSyncGroupsModule
        )
  },
  {
    path: AppRoute.QRCode,
    data: {
      authority: [Authority.Student]
    },
    canLoad: [AuthGuard, RouteGuard],
    loadChildren: () => import('./features/qrcode-reader/qrcode-reader.module').then(m => m.QrcodeModule)
  },
  {
    path: AppRoute.Notifications,
    loadChildren: () => import('./features/notifications/notifications.module').then(m => m.NotificationsModule),
    canLoad: [AuthGuard, RouteGuard]
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules
})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
