import { Injectable } from '@angular/core';
import { NotificationService } from '../../http/notification/notification.service';
import { PushNotifications, Token } from '@capacitor/push-notifications';
import { Router } from '@angular/router';
import { AppRoute } from '../../../../constants/app.route';
import { LiveNotificationService } from '../live-notification.service';

@Injectable({
    providedIn: 'root'
})
export class MobilePushSubscriberService {
    public constructor(
        private notificationService: NotificationService,
        private liveNotificationService: LiveNotificationService,
        private router: Router
    ) {}

    public subscribe(): void {
        PushNotifications.requestPermissions().then((result: { receive: string; }) => {
            if (result.receive === 'granted') {
                PushNotifications.register();
            }
        });

        // On success, we should be able to receive notifications
        PushNotifications.addListener('registration',
            (token: Token) => {
                this.notificationService.subscribeForDeviceNotifications({
                    deviceToken: token.value
                }).subscribe();
            }
        );

        PushNotifications.addListener('pushNotificationReceived', () => {
            this.liveNotificationService.updateUnreadCount();
        });

        // Some issue with our setup and push will not work
        PushNotifications.addListener('registrationError',
            (error: any) => {
                console.error('Error on registration: ' + JSON.stringify(error));
            }
        );

        // Method called when tapping on a notification
        PushNotifications.addListener('pushNotificationActionPerformed',
            () => {
                this.router.navigate(['/', AppRoute.Notifications]);
            }
        );
    }
}
