import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthFactoryService } from '../auth/auth-factory.service';
import { Paging } from './general/classes/paging';
import { HttpOptions } from './general/interfaces/http-options';
import { RestGenericUtilService } from './rest-generic-util.service';

export abstract class RestGeneric<RequestType, ReturnType, RequestUpdateType> {
  private schoolUUidData: string | undefined;
  protected get schoolUUid(): string {
    return this.getSchoolUuid();
  }

  protected readonly baseApiUrl = environment.EduSpireApi.baseUrlWithSegment;

  constructor(
    protected http: HttpClient,
    protected authFactoryService: AuthFactoryService,
    protected endpointName: string,
    protected restGenericUtilService: RestGenericUtilService
  ) {
    this.getSchoolUuid();
  }

  getAllFull(paging = new Paging(), filter?: Record<string, any>): Observable<HttpResponse<ReturnType[]>> {
    const params = this.restGenericUtilService.toParams({ ...paging.getApiPaginationData(), ...filter });

    return this.http.get<ReturnType[]>(`${this.baseApiUrl}/${this.schoolUUid}/${this.endpointName}`, {
      observe: 'response',
      params
    });
  }

  getAll(
    paging = new Paging(),
    filter?: Record<string, any>,
    headers?: Record<string, string>
  ): Observable<ReturnType[]> {
    const params = this.restGenericUtilService.toParams({ ...paging.getApiPaginationData(), ...filter });

    return this.http.get<ReturnType[]>(`${this.baseApiUrl}/${this.schoolUUid}/${this.endpointName}`, {
      params,
      headers
    });
  }

  getById(uuid: string): Observable<ReturnType> {
    return this.http.get<ReturnType>(`${this.baseApiUrl}/${this.endpointName}/${uuid}`);
  }

  post(requestType: RequestType): Observable<ReturnType> {
    return this.http.post<ReturnType>(`${this.baseApiUrl}/${this.schoolUUid}/${this.endpointName}`, requestType);
  }

  postCopy(uuid: string): Observable<ReturnType> {
    return this.http.post<ReturnType>(`${this.baseApiUrl}/${this.endpointName}/${uuid}/copy`, uuid);
  }

  postBulkCopy(uuids: string[]): Observable<ReturnType> {
    return this.http.post<ReturnType>(`${this.baseApiUrl}/${this.schoolUUid}/${this.endpointName}/copy/batch`, uuids);
  }

  postBulkDelete(uuids: string[]): Observable<ReturnType> {
    return this.http.post<ReturnType>(`${this.baseApiUrl}/${this.endpointName}:batchDelete`, {uuids});
  }

  delete(uuid: string): Observable<ReturnType> {
    return this.http.delete<ReturnType>(`${this.baseApiUrl}/${this.endpointName}/${uuid}`);
  }

  put(updateType: RequestUpdateType, uuid: string): Observable<ReturnType> {
    return this.http.put<ReturnType>(`${this.baseApiUrl}/${this.endpointName}/${uuid}`, updateType);
  }

  patch(updateType: Partial<RequestUpdateType>, uuid: string): Observable<ReturnType> {
    return this.http.patch<ReturnType>(`${this.baseApiUrl}/${this.endpointName}/${uuid}`, updateType);
  }

  export(options: HttpOptions): Observable<ArrayBuffer> {
    return this.http.post(`${this.baseApiUrl}/${this.schoolUUid}/${this.endpointName}/export`, options.params, {
      headers: options.headers,
      responseType: 'arraybuffer'
    });
  }

  exportToPdf(options: HttpOptions): Observable<ArrayBuffer> {
    return this.http.post(`${this.baseApiUrl}/${this.schoolUUid}/${this.endpointName}/print`, options.params, {
      headers: options.headers,
      responseType: 'arraybuffer'
    });
  }

  getEndpointName(): string {
    return this.endpointName;
  }

  private getSchoolUuid(): string {
    if (this.schoolUUidData) {
      return this.schoolUUidData;
    }

    const schoolUserAuthority = this.authFactoryService.service.getSchoolUserAuthority();

    if (!schoolUserAuthority) {
      throwError('schoolUserAuthority is null');
    }

    this.schoolUUidData = schoolUserAuthority?.uuid;

    if (!this.schoolUUidData || this.schoolUUidData?.length === 0) {
      throwError('schoolUUid is null');
    }

    return this.schoolUUidData || '';
  }
}
