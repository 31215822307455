import { Component, OnDestroy } from '@angular/core';

import { Subject } from 'rxjs';

@Component({
  template: ''
})
export class DestroyNotifierComponent implements OnDestroy {
  public destroyNotify = new Subject();

  ngOnDestroy() {
    this.destroyNotify.next();
    this.destroyNotify.complete();
  }
}
