import { ElementRef, Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Observable } from 'rxjs';
import { AppRoute } from 'src/app/constants/app.route';
import { InHouseProvider } from '../provider-builder/interfaces/in-house-provider';
import { PlatformDevice } from './platform-device';

@Injectable({
  providedIn: 'root'
})
export abstract class PlatformAbstractService implements PlatformDevice {
  constructor(protected router: Router, protected zone: NgZone) {}

  platformClassChange: Observable<string> | undefined;

  // Hooks on the external call of the app (when we hit similar address in the mobile browser `eduspire-app://`)
  // This is used when we have TParty auth providers like Google Microsoft 365 etc.
  hookDeepLinks(): void {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        const authcallbackSegment = `${AppRoute.AuthCallBack}`;
        const authcallback = authcallbackSegment + event.url.split(authcallbackSegment)[1];

        this.onAppUrlOpen(authcallback);
      });
    });
  }

  onAppUrlOpen(authcallback: string): void {
    this.router.navigateByUrl(authcallback);
  }

  init(): void {
    this.hookDeepLinks();
  }

  onSubmit(options: {
    form: ElementRef<any>;
    username: string;
    password: string;
    inHouseProvider: InHouseProvider;
  }): void {
    options.form.nativeElement.submit();
  }
}
