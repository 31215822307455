import { EventEmitter, Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { AuthFactoryService } from '../auth/auth-factory.service';
import { interval } from 'rxjs';
import { timeInterval, mergeMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { School } from '../http/school/interfaces/school';
import { EventManagerService } from '../event-manager/event-manager.service';
import { EventContent } from '../event-manager/models/event-content';
import { Notification } from '../http/notification/interfaces/notification';
import { EventType } from '../../../constants/events-type';
import { Subscription } from 'rxjs';
import { NotificationService } from '../http/notification/notification.service';

@Injectable({
    providedIn: 'root'
})
export class LiveNotificationService {

    public unreadNotificationCountChanged: EventEmitter<number> = new EventEmitter<number>();
    public unreadCount = 0;

    private readonly LIVE_FEED_ENDPOINT = '/me/notifications/live';
    private readonly REFRESH_INTERVAL = 60;
    private subscription: Subscription|null = null;

    public constructor(
        private http: HttpClient,
        private authFactoryService: AuthFactoryService,
        private eventManager: EventManagerService,
        private notificationService: NotificationService
    ) {
    }

    public subscribe(): void {
        const school: School|null = this.authFactoryService.service.getSchoolUserAuthority();

        if (school) {
            if (this.subscription !== null) {
                this.subscription.unsubscribe();
            }

            this.updateUnreadCount();

            this.subscription =
                interval(this.REFRESH_INTERVAL * 1000)
                    .pipe(timeInterval())
                    .pipe(mergeMap(() => {
                        return this.http.get<Array<Notification>>(
                            environment.EduSpireApi.domainNotificationsUrl + this.LIVE_FEED_ENDPOINT,
                        );
                    }))
                    .subscribe(
                        (notificationsResponse: Array<Notification>) => {
                            notificationsResponse.forEach((notification: Notification) => {
                                this.eventManager.broadcast(new EventContent<Notification>(
                                    EventType.Notification,
                                    notification
                                ));
                            });

                            if (notificationsResponse.length > 0) {
                                this.updateUnreadCount();
                            }
                        }
                    );
        }
    }

    public updateUnreadCount(): void
    {
        this.notificationService.getUnreadCount().subscribe((response: {unreadCount: number}) => {
            this.unreadCount = response.unreadCount;
            this.unreadNotificationCountChanged.emit(this.unreadCount);
        });
    }
}
