import { NgModule } from '@angular/core';
import { SanitizeHtmlPipe } from '../core/pipes/dom-sanitize/dom-sanitizer.pipe';
import { ExtendedDatePipe } from '../core/pipes/extended-date/extended-date-pipe';
import { JoinArrayPipe } from '../core/pipes/join-array/join-array.pipe';
import { SanitizeLinkPipe } from '../core/pipes/link-sanitize/link-sanitizer.pipe';
import { SafeUrlPipe } from '../core/pipes/save-url/save-url.pipe';
import { SplitPipe } from '../core/pipes/split/split.pipe';
import { SubStringPipe } from '../core/pipes/substring/substring.pipe';
import { TimeByShiftUtcPipe } from '../core/pipes/time-by-shift-utc/time-by-shift-utc.pipe';
import { WeekNumbersToDaysPipe } from '../core/pipes/week-number-to-days/week-numbers-to-days.pipe';

@NgModule({
  declarations: [
    SubStringPipe,
    SplitPipe,
    SanitizeHtmlPipe,
    SanitizeLinkPipe,
    SafeUrlPipe,
    WeekNumbersToDaysPipe,
    TimeByShiftUtcPipe,
    JoinArrayPipe,
    ExtendedDatePipe
  ],
  exports: [
    SubStringPipe,
    SplitPipe,
    SanitizeHtmlPipe,
    SanitizeLinkPipe,
    SafeUrlPipe,
    WeekNumbersToDaysPipe,
    TimeByShiftUtcPipe,
    JoinArrayPipe,
    ExtendedDatePipe
  ]
})
export class PipesModule {}
