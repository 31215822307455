import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { SchoolYearRegistry } from '../services/date-manager/school-year/school-year.registry';
import { AuthFactoryService } from '../services/auth/auth-factory.service';

@Injectable({
    providedIn: 'root'
})
export class SchoolYearInterceptor implements HttpInterceptor {
    constructor(
        private schoolYearRegistry: SchoolYearRegistry,
        private authFactoryService: AuthFactoryService
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const schoolYear: number | undefined = this.schoolYearRegistry.selectedSchoolYear?.schoolYear;
        const schoolUuid: string | undefined = this.authFactoryService.service.getSchoolUserAuthority()?.uuid;

        const isRequestToBackendApi = request.url.startsWith(environment.EduSpireApi.domainApiUrl) ||
            request.url.startsWith(environment.EduSpireApi.domainNotificationsUrl);

        if (schoolYear && isRequestToBackendApi && schoolUuid) {
            request = request.clone({
                setHeaders: {
                    'X-Requested-SchoolYear': schoolYear.toString(),
                    'X-Requested-School': schoolUuid
                }
            });
        }

        return next.handle(request);
    }
}
