import { AppConfig } from 'src/app/configs/app.config';
export class Paging {
  constructor(args?: { orderBy?: string; pageSize?: number }) {
    if (args?.orderBy) {
      this.orderBy = args.orderBy;
    }

    if (args?.pageSize) {
      this.pageSize = args.pageSize;
    }
  }

  orderBy = '';
  // The current page number
  page = 1;
  // The total number of elements
  totalElements = 0;
  // The total number of pages
  totalPages = 0;
  // The number of elements in the page
  pageSize = AppConfig.Api.pageSize;

  getApiPaginationData(): { page: number; pageSize: number; orderBy: string } {
    return { page: this.page, pageSize: this.pageSize, orderBy: this.orderBy };
  }

  getAllRecords(): Paging {
    this.pageSize = AppConfig.Api.pageSizeAll;
    return this;
  }

  getChunkOfRecords(chunk: number): Paging {
    this.pageSize = chunk;
    return this;
  }
}
