import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  constructor(private storage: Storage) {}

  setItem(key: LocalStorageKeys, value: any): void {
    this.storage.setItem(key, value);
  }

  getItem(key: LocalStorageKeys): string {
    return this.storage.getItem(key) || '';
  }

  removeItem(key: LocalStorageKeys): void {
    this.storage.removeItem(key);
  }

  clearAll(): void {
    this.storage.clear();
  }

  getObject(key: LocalStorageKeys): any | undefined {
    const objectString = this.getItem(key);

    if (objectString) {
      return JSON.parse(objectString);
    }
  }

  setObject(key: LocalStorageKeys, obj: any): void {
    this.setItem(key, JSON.stringify(obj));
  }
}

type LocalStorageKeys =
    | 'cachedPeriod'
    | 'schoolUserAuthority'
    | 'token'
    | 'tokenExpiration'
    | 'tokenImpersonate'
    | 'tokenImpersonateExpiration'
    | 'userImpersonate'
    | 'schoolUserAuthorityImpersonate'
    | 'activityPriority'
    | 'verifierCode'
    | 'systemSettings'
    | 'schoolYear'
    | 'authenticatedUser'
    | 'schoolYearSettings'
    | 'announcements'
    | '__gga_page_counter'
    | string;
